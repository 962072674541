import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SideDrawer from "./SideDrawer";
import {
  Card,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import { getUserDetails } from "../../apis/UserApis";
import { getUsersTotalAmt } from "../../apis/orderApis";

const UserDetails = () => {
  const location = useLocation();

  const userId = location.state && location.state.data;
  const [user, setUser] = useState();
  const [data, setDate] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const req = await getUserDetails(userId);

      const reqData = await getUsersTotalAmt(userId);

      if (reqData) {
        setDate(reqData);
      }

      if (req) {
        setUser(req);
      }
    }
    fetchData();
  }, [userId]);
  const convertNameToCSV = () => {
    const csvData = [];
    // Add headers to CSV data
    csvData.push(["Sr No", "Name", "Composition", "Quantity", "total"]);

    // Add rows to CSV data
    data.length > 0 &&
      data.forEach((row, index) => {
        csvData.push([
          index + 1,
          row._id?.name,
          row._id?.composition,
          row.totalQuantity,
          row.totalAmount,
        ]);
      });

    return csvData;
  };

  return (
    <div className="userDetails">
      <SideDrawer />
      <Card w="100%">
        <Heading as="h2" size="lg" mb={4} ml={4}>
          User Report
        </Heading>
        <CardBody backgroundColor={"#D9d9d9"} margin={4} p={16}>
          <p>
            User Id : <span>{user?._id}</span>
          </p>
          <p>
            Client name : <span>{user?.name}</span>
          </p>
          <p>
            Email : <span>{user?.email}</span>
          </p>
          <p>
            Phone : <span>{user?.mobile}</span>
          </p>
          <p>
            Type : <span>{user?.userType}</span>
          </p>

          <TableContainer mt={4}>
            <CSVLink
              data={convertNameToCSV()}
              filename={"user_report.csv"}
              className="downloadButton"
            >
              Export <SiMicrosoftexcel style={{ marginLeft: "15px" }} />
            </CSVLink>
            <Table size="md" borderWidth="1px" border="1px solid">
              <Thead backgroundColor={"#252B6194"} color={"black"}>
                <Tr borderWidth="1px">
                  <Th width={10}>#</Th>
                  <Th>Name</Th>
                  <Th>Composition</Th>
                  <Th>Quantity</Th>
                  <Th>Amount</Th>
                </Tr>
              </Thead>
              <Tbody borderWidth="1px">
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <Tr
                      key={index}
                      _hover={{ background: "#f5f5f5", cursor: "pointer" }}
                    >
                      <Td>{index + 1}</Td>
                      <Td>{item?._id?.name}</Td>
                      <Td>{item?._id?.composition}</Td>
                      <Td>{item.totalQuantity}</Td>
                      <Td>{item.totalAmount}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td>No Orders</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default UserDetails;
