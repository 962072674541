import React, { useState, useEffect } from "react";
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  useDisclosure,
  Radio,
  RadioGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  HStack,
  VStack,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  findProducts,
  getAllCategory,
  getAllComposition,
  getAllPackingType,
  getAllPreparation,
  getAllProducts,
  getAllgst,
  getProductsbasedonCategory,
} from "../../apis/productApis";
import { postOrder } from "../../apis/orderApis";
import { useAppData } from "../../hooks/AppDataContext";
import { color } from "framer-motion";
import { useNavigate } from "react-router-dom";

const calculateTotalAmount = (index, selectedProduct, Quantity) => {
  Quantity = Quantity * 12;
  console.log("quantity", Quantity);

  let totalAmount = 0;
  let gst_amount = 0;

  if (Quantity) {
    if (selectedProduct?.isCustom) {
      // Custom products use a fixed amount
      totalAmount = selectedProduct?.amount || 0;
      gst_amount = ((selectedProduct?.gst_percentage || 0) * totalAmount) / 100;
    } else {
      // For non-custom products, calculate billingAmount based on Quantity
      const billingAmount = billingPriceAmt(index, selectedProduct, Quantity);
      totalAmount = billingAmount * Quantity; // Multiply billingAmount by Quantity
      gst_amount =
        (billingAmount * (selectedProduct?.gst_percentage || 0)) / 100;
    }
  }

  return { totalAmount, gst_amount };
};

const billingPriceAmt = (index, selectedProduct, Quantity) => {
  Quantity = Quantity * 12; // Assuming this is correct based on your logic

  if (selectedProduct?.isCustom) {
    return selectedProduct?.tax_amount; // For custom products, tax_amount is used
  }
  if (
    !selectedProduct ||
    !Quantity ||
    isNaN(selectedProduct?.batch_price) ||
    isNaN(selectedProduct?.batch_qty)
  ) {
    return 0;
  }

  const batchRate = selectedProduct?.batch_price / selectedProduct?.batch_qty;

  // Pricing logic based on Quantity
  if (Quantity < (1 / 3) * selectedProduct?.batch_qty) {
    return Math.round((500 / 100) * batchRate + batchRate);
  } else if (
    Quantity >= (1 / 3) * selectedProduct?.batch_qty &&
    Quantity <= (1 / 2) * selectedProduct?.batch_qty - 1
  ) {
    return Math.round((300 / 100) * batchRate + batchRate);
  } else if (
    Quantity >= (1 / 2) * selectedProduct?.batch_qty &&
    Quantity <= selectedProduct?.batch_qty - 1
  ) {
    return Math.round((200 / 100) * batchRate + batchRate);
  } else if (
    Quantity >= 1 * selectedProduct?.batch_qty &&
    Quantity <= 2 * selectedProduct?.batch_qty - 1
  ) {
    return Math.round((50 / 100) * batchRate + batchRate);
  } else if (
    Quantity >= 2 * selectedProduct?.batch_qty &&
    Quantity <= 3 * selectedProduct?.batch_qty - 1
  ) {
    return Math.round((30 / 100) * batchRate + batchRate);
  } else {
    return Math.round((20 / 100) * batchRate + batchRate);
  }
};

// Components
const CategoryModal = ({ isOpen, onClose, categories, onSelect }) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered size={"md"}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Choose Category</ModalHeader>
      <ModalBody>
        <RadioGroup
          onChange={onSelect}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {categories.map((category) => (
            <Radio key={category._id} value={category.name}>
              {category.name}
            </Radio>
          ))}
        </RadioGroup>
        <Box textAlign="end" mt={4}>
          <Button colorScheme="blue" onClick={onClose}>
            Next
          </Button>
        </Box>
      </ModalBody>
    </ModalContent>
  </Modal>
);

const ProductSearch = ({
  searchTerm,
  onSearchChange,
  products,
  onSelect,
  index,
}) => (
  <div>
    <Input
      type="text"
      placeholder="Search Name or Composition"
      value={searchTerm}
      onChange={(e) => onSearchChange(e.target.value, index)}
    />
    {searchTerm.length > 0 && products.length > 0 && (
      <ul
        className="Search-term"
        style={{ height: "250px", overflowY: "scroll" }}
      >
        {products.map((option, idx) => (
          <li
            key={idx}
            onClick={() => onSelect(option, index)}
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "white",
              margin: "5px",
              borderRadius: "5px",
            }}
          >
            {option?.name} | {option?.composition?.name}
          </li>
        ))}
      </ul>
    )}
  </div>
);

const QuantityInput = ({ value, onChange, onIncrement, onDecrement }) => (
  <HStack spacing={4}>
    <Button onClick={onDecrement} disabled={value <= 0}>
      -
    </Button>
    <Input
      className="monthlyDispatchInput"
      type="number"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      min={0}
    />
    <Button onClick={onIncrement}>+</Button>
  </HStack>
);
const OrderTable = ({
  searchTerms,
  handleSelectProduct,
  handleSearchChange,
  orderArray,
  products,
  Quantity,
  selectedCategory,
  onDelete,
  onQuantityChange,
  onAddRow,
  onAddCustomModal,
  totalAmount,
  orderNow,
  isVisible,
  onClose,
  isOrderValid,
  quantityError,
  setQuantityError,
  handleMonthlyDispatchChange,
}) => {
  return (
    <div className="businesshome">
      {/* {isVisible ? (
      <Alert status="success">
        <AlertIcon />
        <Box>
          <AlertTitle>Success!</AlertTitle>
          <AlertDescription>
            Product order added successfully!!!
          </AlertDescription>
        </Box>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      </Alert>
    ) : null} */}
      {quantityError && (
        <Alert status="error">
          <AlertIcon />
          <Box>
            <AlertTitle>Error!</AlertTitle>
            <AlertDescription>{quantityError}</AlertDescription>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => setQuantityError("")}
          />
        </Alert>
      )}
      <div className="resoponsiveTable">
        <Table>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Sr no.</Th>
              <Th>Composition/name</Th>
              <Th>Monthly Moq</Th>
              {!selectedCategory || selectedCategory !== "Medical Device" ? (
                <>
                  <Th>Monthly Dispatch</Th>
                  <Th>Annual Quantity</Th>
                </>
              ) : null}
              <Th>GST%</Th>
              <Th>Billing Rate</Th>
              <Th>MRP</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderArray.map((product, index) => {
              console.log(product);
              const { totalAmount, gst_amount } = calculateTotalAmount(
                index,
                product,
                Quantity[index],
              );
              const monthlyDispatch = Quantity[index] || 0;
              const annualQuantity = monthlyDispatch * 12;
              return (
                <Tr key={index}>
                  <Td>
                    <DeleteIcon onClick={() => onDelete(index)} />
                  </Td>
                  <Td>{index + 1}</Td>
                  <Td>
                    {!product?.name ? (
                      <ProductSearch
                        searchTerm={searchTerms[index]}
                        onSearchChange={(term) =>
                          handleSearchChange(term, index)
                        }
                        products={products}
                        onSelect={(product) =>
                          handleSelectProduct(product, index)
                        }
                        index={index}
                      />
                    ) : (
                      `${product?.name} | ${
                        product?.composition?.name || product?.composition
                      } | ${
                        product?.packing_type?.type || product?.packing_type
                      } | ${
                        product?.preparation_type?.name ||
                        product?.preparation_type
                      }`
                    )}
                  </Td>
                  <Td>{product?.Moq || ""}</Td>
                  {!selectedCategory ||
                  selectedCategory !== "Medical Device" ? (
                    <>
                      <Td>
                        {product.isCustom ? (
                          <Input
                            type="number"
                            value={monthlyDispatch}
                            disabled={true}
                          />
                        ) : (
                          <QuantityInput
                            value={monthlyDispatch}
                            onChange={(value) => onQuantityChange(index, value)}
                            onIncrement={() =>
                              handleMonthlyDispatchChange(
                                index,
                                product.shipper_pack_qty,
                              )
                            }
                            onDecrement={() =>
                              handleMonthlyDispatchChange(
                                index,
                                -product.shipper_pack_qty,
                              )
                            }
                          />
                        )}
                      </Td>
                      <Td>{annualQuantity || ""}</Td>
                    </>
                  ) : null}
                  <Td>
                    {product?.gst_percentage?.name || product?.gst_percentage}
                  </Td>
                  <Td>
                    {product?.isCustom
                      ? product?.tax_amount // Display tax_amount for custom products
                      : product?.tax_amount || ""}
                  </Td>
                  <Td>{product?.mrp || ""}</Td>
                  <Td>
                    {product?.isCustom ? product?.amount : totalAmount || ""}
                  </Td>
                </Tr>
              );
            })}
            <Tr>
              <Td colSpan="9">
                <Button colorScheme="blue" onClick={onAddRow}>
                  Add Row +
                </Button>
              </Td>
              <Td>{totalAmount}</Td>
            </Tr>
            <Tr>
              <Td colSpan="10">
                <Button
                  style={{ marginLeft: 10 }}
                  colorScheme="blue"
                  onClick={onAddCustomModal}
                >
                  Add Customized Product
                </Button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <div className="orderBtn">
        <Button className="orBtn" onClick={orderNow} disabled={!isOrderValid()}>
          Order Now
        </Button>
      </div>
    </div>
  );
};

// Main Component
const Home = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    isOpen: isModalOpen,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const { appData } = useAppData();
  const { user } = appData;
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure();

  const [gstType, setGstType] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerms, setSearchTerms] = useState([""]); // Store search terms for each row
  const [products, setProducts] = useState([]);
  const [quantityError, setQuantityError] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalGst, setTotalGst] = useState(0);

  const [orderArray, setOrderArray] = useState([{}]);
  const [quantity, setQuantity] = useState([0]);
  const [categories, setCategories] = useState([]);
  const [packings, setPackings] = useState([]);
  const [prepTypes, setPrepTypes] = useState([]);
  const [gstDatas, setGstDatas] = useState([]);
  const [composition, setComposition] = useState([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [totalQty, setTotalQuantity] = useState(0);
  const [ttltaxAmt, setTotalTaxAmount] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    composition: "",
    product_category: "",
    sub_category: "",
    packing_type: "",
    preparation_type: "",
    Moq: "",
    quantity: "",
    tax_amount: "",
    gst_amount: "",
    gst_percentage: "",
    mrp: "",
    amount: "",
  });

  useEffect(() => {
    async function fetchData() {
      const storedUser = localStorage.getItem("user");
      const [categoriesData, packingData, prepData, gstData, compositionData] =
        await Promise.all([
          getAllCategory(),
          getAllPackingType(),
          getAllPreparation(),
          getAllgst(),
          getAllComposition(),
        ]);

      setCategories(categoriesData);
      setPackings(packingData);
      setPrepTypes(prepData);
      setGstDatas(gstData);
      setComposition(compositionData);

      if (user?.state.toLowerCase() === "karnataka") {
        setGstType("IGST");
      } else {
        setGstType("CGST");
      }
    }
    fetchData();
  }, [user]);

  useEffect(() => {
    const updatedOrderArray = orderArray.map((item, index) => {
      const { totalAmount, gst_amount } = calculateTotalAmount(
        index,
        item,
        quantity[index],
      );
      console.log(totalAmount, gst_amount);
      if (index === orderArray.length - 1) {
        return {
          ...item,
          quantity: parseFloat(quantity[index]),
          tax_amount: billingPriceAmt(index, item, quantity[index]),
          gst_amount: gst_amount.toFixed(2),
          amount: Math.round(totalAmount + 1),
          per_unit: (totalAmount / parseFloat(quantity[index])).toFixed(2),
        };
      }
      return item;
    });
    calculateTotals(updatedOrderArray);
    setOrderArray(updatedOrderArray);
  }, [quantity]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setProducts([]);
    setOrderArray([]);
    onCloseModal();
  };

  const handleSearchChange = (term, index) => {
    const updatedSearchTerms = [...searchTerms];
    updatedSearchTerms[index] = term;
    setSearchTerms(updatedSearchTerms);

    if (term) {
      findProducts(term).then((data) => {
        console.log(data);
        setProducts(data);
      });
    } else {
      setProducts([]);
    }
  };

  const handleSelectProduct = (product, index) => {
    // Clone the orderArray to avoid direct mutation
    const updatedOrderArray = [...orderArray];

    // Extract the required fields from the nested objects
    const {
      composition,
      packing_type,
      preparation_type,
      gst_percentage,
      ...productWithoutNestedObjects
    } = product;

    // Create a new product object with only the required fields
    const updatedProduct = {
      ...productWithoutNestedObjects,
      composition: composition ? composition.name : composition, // Extract composition name
      packing_type: packing_type ? packing_type.type : packing_type, // Extract packing type
      preparation_type: preparation_type
        ? preparation_type.name
        : preparation_type, // Extract preparation type name
      gst_percentage: gst_percentage ? gst_percentage.name : gst_percentage, // Extract GST percentage name
    };

    // Update the orderArray with the new product
    updatedOrderArray[index] = updatedProduct;

    // Update the state with the new orderArray
    setOrderArray(updatedOrderArray);

    // Reset the quantity and searchTerms at the given index
    setQuantity([...quantity.slice(0, index), 0, ...quantity.slice(index + 1)]);
    setSearchTerms([
      ...searchTerms.slice(0, index),
      "",
      ...searchTerms.slice(index + 1),
    ]);

    // Clear the product list and recalculate totals
    setProducts([]);
    calculateTotals(updatedOrderArray);
  };

  const calculateTotals = (orderArray) => {
    console.log("orderArrat");
    console.log(orderArray);
    let totalAmount = 0;
    let totalGstAmount = 0;
    let totalQty = 0;
    let totalTaxAmount = 0;

    orderArray.forEach((product, index) => {
      const { totalAmount: amount, gst_amount } = calculateTotalAmount(
        index,
        product,
        quantity[index],
      );
      totalAmount += amount;
      totalGstAmount += gst_amount;

      totalQty += Number(quantity[index]) || 0;
      totalTaxAmount += parseFloat(product.tax_amount) || 0;
    });

    setTotalAmount(totalAmount);
    setTotalGst(totalGstAmount);
    setTotalQuantity(totalQty);
    setTotalTaxAmount(totalTaxAmount);
  };

  const handleQuantityChange = (index, value) => {
    console.log("quantity " + value);
    const updatedQuantity = [...quantity];
    updatedQuantity[index] = value;
    setQuantity(updatedQuantity);

    const updatedOrderArray = [...orderArray];
    const { totalAmount, gst_amount } = calculateTotalAmount(
      index,
      updatedOrderArray[index],
      value,
    );

    updatedOrderArray[index] = {
      ...updatedOrderArray[index],
      quantity: parseFloat(value),
      tax_amount: billingPriceAmt(index, updatedOrderArray[index], value),
      gst_amount: gst_amount.toFixed(2),
      amount: Math.round(totalAmount + 1),
      per_unit: (totalAmount / parseFloat(value)).toFixed(2),
    };

    setOrderArray(updatedOrderArray);
    calculateTotals(updatedOrderArray);
  };

  const handleAddRow = () => {
    setOrderArray([...orderArray, {}]);
    setQuantity([...quantity, 0]);
    setSearchTerms([...searchTerms, ""]); // Add an empty search term for the new row
  };

  const handleAddCustomModal = () => {
    setIsAddModalOpen(true);
  };
  useEffect(() => {
    if (formData.quantity && formData.tax_amount && formData.gst_percentage) {
      const quantity = parseFloat(formData.quantity) || 0;
      const taxAmount = parseFloat(formData.tax_amount) || 0;
      const gstPercentage = parseFloat(formData.gst_percentage) || 0;

      const totalAmount = quantity * taxAmount * 12;
      const gstAmount = (totalAmount * gstPercentage) / 100;
      const total = totalAmount + gstAmount;

      setFormData((prevFormData) => ({
        ...prevFormData,
        amount: total,
      }));
    }
  }, [formData.quantity, formData.tax_amount, formData.gst_percentage]);

  const handleAddCustomProduct = (e) => {
    e.preventDefault();
    if (parseFloat(formData.quantity) < parseFloat(formData.Moq)) {
      setQuantityError(`Quantity cannot be less than MOQ (${formData.Moq})`);
      return;
    }

    const quantity = parseFloat(formData.quantity) || 0;
    const moq = parseFloat(formData.Moq) || 0;
    const taxAmount = parseFloat(formData.tax_amount) || 0;
    const gstPercentage = parseFloat(formData.gst_percentage) || 0;
    const mrp = parseFloat(formData.mrp) || 0;

    const totalAmount = quantity * taxAmount * 12;
    const gstAmount = (totalAmount * gstPercentage) / 100;
    const total = totalAmount + gstAmount;

    setQuantityError("");

    const newProduct = {
      ...formData,
      Moq: moq,
      quantity: quantity,
      gst_amount: gstAmount,
      tax_amount: taxAmount,
      mrp: mrp,
      amount: total,
      gst_percentage: gstPercentage,
      monthlyDispatch: formData.monthlyDispatch
        ? parseFloat(formData.monthlyDispatch) || 0
        : 0,
      isCustom: true,
    };

    setOrderArray((prevOrderArray) => [...prevOrderArray, newProduct]);
    setQuantity((prevQuantity) => [...prevQuantity, quantity]);
    setSearchTerms((prevSearchTerms) => [...prevSearchTerms, ""]);

    setFormData({
      name: "",
      composition: "",
      product_category: "",
      sub_category: "",
      packing_type: "",
      preparation_type: "",
      Moq: "",
      quantity: "",
      tax_amount: "",
      gst_amount: "",
      gst_percentage: "",
      mrp: "",
      amount: "",
      monthlyDispatch: "",
    });

    setIsAddModalOpen(false);

    calculateTotals([...orderArray, newProduct]);
  };

  const handleDelete = (index) => {
    const updatedOrderArray = [...orderArray];
    updatedOrderArray.splice(index, 1);
    setOrderArray(updatedOrderArray);
    const updatedQuantity = [...quantity];
    updatedQuantity.splice(index, 1);
    setQuantity(updatedQuantity);
    const updatedSearchTerms = [...searchTerms];
    updatedSearchTerms.splice(index, 1);
    setSearchTerms(updatedSearchTerms);
    calculateTotals(updatedOrderArray);
  };

  const validateQuantities = () => {
    for (let i = 0; i < orderArray.length; i++) {
      const product = orderArray[i];
      if (quantity[i] < product.Moq) {
        return `Quantity for product ${product.name} cannot be less than MOQ (${product.Moq})`;
      }
    }
    return null;
  };

  const handleCompositionChange = (e) => {
    const selectedCompositionId = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      composition: selectedCompositionId,
      Moq:
        composition.find((item) => item.name === selectedCompositionId)?.moq ||
        0,
    }));
  };

  const handleMonthlyDispatchChange = (index, delta) => {
    const updatedOrderArray = [...orderArray];
    const product = updatedOrderArray[index];

    const newDispatch = (product.monthlyDispatch || 0) + delta;

    const validDispatch = Math.max(
      0,
      newDispatch - (newDispatch % product.shipper_pack_qty),
    );

    updatedOrderArray[index] = {
      ...product,
      monthlyDispatch: validDispatch,
      annualQuantity: validDispatch * 12,
    };

    setOrderArray(updatedOrderArray);
    setQuantity((prev) => {
      const updatedQuantity = [...prev];
      updatedQuantity[index] = validDispatch;
      return updatedQuantity;
    });

    calculateTotals(updatedOrderArray);
  };

  const orderNow = async () => {
    const validationError = validateQuantities();
    if (validationError) {
      setQuantityError(validationError);
      return;
    }
    if (!isOrderValid()) {
      setQuantityError(
        "Add atleast one product / The field shouldn’t be empty",
      );
      return;
    }

    const data = {
      userId: user._id,
      products: orderArray,
      total_amount: totalAmount,
      gst_type: gstType,
      total_quantity: totalQty,
      total_tax_amt: ttltaxAmt,
      total_gst: totalGst,
    };
    const req = await postOrder(data);
    console.log("req", req);
    if (req.message == "Product order added successfully") {
      toast({
        title: req.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });

      navigate("/myorders");
    }
    onOpen();
  };
  const isOrderValid = () => {
    if (orderArray.length <= 0) {
      return false;
    }
    return orderArray.every((product) => product.name && product.quantity);
  };

  return (
    <div>
      <CategoryModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        categories={categories}
        onSelect={handleCategorySelect}
      />
      <OrderTable
        searchTerms={searchTerms}
        handleSelectProduct={handleSelectProduct}
        handleSearchChange={handleSearchChange}
        orderArray={orderArray}
        products={products}
        Quantity={quantity}
        selectedCategory={selectedCategory}
        onDelete={handleDelete}
        onQuantityChange={handleQuantityChange}
        onAddRow={handleAddRow}
        onAddCustomModal={handleAddCustomModal}
        totalAmount={totalAmount}
        orderNow={orderNow}
        isVisible={isVisible}
        onClose={onClose}
        isOrderValid={isOrderValid}
        quantityError={quantityError}
        setQuantityError={setQuantityError}
        handleMonthlyDispatchChange={handleMonthlyDispatchChange}
      />
      <Modal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Custom Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleAddCustomProduct}>
              <HStack
                style={{ justifyContent: "space-around", padding: "1rem" }}
              >
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Product Name<span>*</span>
                  </label>
                  <Input
                    value={formData?.name || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Product Name"
                    required
                  />
                </VStack>
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Category<span>*</span>
                  </label>
                  <Select
                    name="product_category"
                    value={formData.product_category || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        product_category: e.target.value,
                      })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Select Category"
                  >
                    {categories.length > 0 &&
                      categories.map((item) => (
                        <option value={item?.name} key={item?._id}>
                          {item?.name}
                        </option>
                      ))}
                  </Select>
                </VStack>
              </HStack>
              <HStack
                style={{ justifyContent: "space-around", padding: "1rem" }}
              >
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Sub Category<span>*</span>
                  </label>
                  <Select
                    required
                    name="sub_category"
                    value={formData?.sub_category || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, sub_category: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Select Sub Category"
                  >
                    {formData?.product_category
                      ? categories
                          .find(
                            (category) =>
                              category.name === formData?.product_category,
                          )
                          ?.subcategories?.map((subcategory) => (
                            <option
                              key={subcategory?._id}
                              value={subcategory?._id}
                            >
                              {subcategory?.name}
                            </option>
                          ))
                      : null}
                  </Select>
                </VStack>
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Composition<span>*</span>
                  </label>
                  <Select
                    required
                    value={formData?.composition || ""}
                    onChange={handleCompositionChange}
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Select Composition"
                  >
                    {composition
                      .filter(
                        (item) => item.subcategory_id === formData.sub_category,
                      )
                      .map((item) =>
                        item.active ? (
                          <option key={item?._id} value={item?.name}>
                            {item?.name}
                          </option>
                        ) : null,
                      )}
                  </Select>
                </VStack>
              </HStack>
              <HStack
                style={{ justifyContent: "space-around", padding: "1rem" }}
              >
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Packing Type<span>*</span>
                  </label>
                  <Select
                    required
                    name="packing_type"
                    value={formData?.packing_type || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, packing_type: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Select Packing Type"
                  >
                    {packings.length > 0 &&
                      packings.map((item) => (
                        <option value={item?.type} key={item?._id}>
                          {item?.type}
                        </option>
                      ))}
                  </Select>
                </VStack>
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Preparation Type <span>*</span>
                  </label>
                  <Select
                    required
                    name="preparation_type"
                    value={formData?.preparation_type || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        preparation_type: e.target.value,
                      })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Select Preparation Type"
                  >
                    {prepTypes?.length > 0 &&
                      prepTypes?.map((item) => (
                        <option key={item?._id} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                  </Select>
                </VStack>
              </HStack>
              <HStack
                style={{ justifyContent: "space-around", padding: "1rem" }}
              >
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    MOQ <span>*</span>
                  </label>
                  <Input
                    type="number"
                    name="Moq"
                    value={formData?.Moq || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, Moq: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="MOQ"
                    disabled
                  />
                </VStack>
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Monthly Dispatch <span>*</span>
                  </label>
                  <Input
                    name="quantity"
                    value={formData?.quantity || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, quantity: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Monthly Dispatch"
                    required
                  />
                </VStack>
              </HStack>
              <HStack
                style={{ justifyContent: "space-around", padding: "1rem" }}
              >
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    GST % <span>*</span>
                  </label>
                  <Select
                    required
                    value={formData.gst_percentage || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        gst_percentage: e.target.value,
                      })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Select GST %"
                  >
                    {gstDatas?.length > 0 &&
                      gstDatas?.map((item) => (
                        <option key={item?._id} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                  </Select>
                </VStack>
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    MRP <span>*</span>
                  </label>
                  <Input
                    type="number"
                    value={formData.mrp || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, mrp: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="MRP of Product"
                    required
                  />
                </VStack>
              </HStack>
              <HStack
                style={{ justifyContent: "space-around", padding: "1rem" }}
              >
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Expected Price <span>*</span>
                  </label>
                  <Input
                    type="number"
                    name="tax_amount"
                    value={formData?.tax_amount || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, tax_amount: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Billing Price"
                    required
                  />
                </VStack>
                <VStack style={{ display: "flex", width: "100%" }}>
                  <label className="label">
                    Annual Amount <span>*</span>
                  </label>
                  <Input
                    type="number"
                    name="amount"
                    value={formData.amount || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, amount: e.target.value })
                    }
                    style={{ color: "black" }}
                    _placeholder={{ color: "black" }}
                    placeholder="Amount"
                    readOnly={true}
                    required
                  />
                </VStack>
              </HStack>
              {quantityError && <Text color="red.500">{quantityError}</Text>}
              <HStack
                style={{ justifyContent: "space-around", padding: "1rem" }}
              >
                <Button
                  type="submit"
                  // onClick={handleAddCustomProduct}
                  style={{ backgroundColor: "#252B6194", color: "#FFF" }}
                >
                  ADD
                </Button>
              </HStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Home;
