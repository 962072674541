import React from "react";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Contact from "./components/Contact";
import MyOrders from "./components/business/MyOrders";
import Header from "./components/business/Headers";
import Home from "./components/business/Home";

function BusinessRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/myorders" element={<MyOrders />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default BusinessRouter;
