import React, { createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import { AppDataProvider, useAppData } from "./hooks/AppDataContext";
import AdminRouter from "./AdminRouter";
import BusinessRouter from "./BusinessRouter";
import Home from "./components/Home ";
import AdminLogin from "./components/admin/AdminLogin";
import Login from "./components/business/Login";
import SignUp from "./components/business/SignUp";

import "./styles/App.scss";
import "./styles/contact.scss";
import "./styles/footer.scss";
import "./styles/header.scss";
import "./styles/home.scss";
import "./styles/mediaquery.scss";
import "./styles/commingsoon.scss";
import "./styles/adminLogin.scss";
import "./styles/login.scss";
import "./styles/SignUp.scss";
import "./styles/business/myorders.scss";
import "./styles/business/businesshome.scss";
import "./styles/admin/Drawer.scss";
import "./styles/admin/AdminHome.scss";
import "./styles/admin/PendingReport.scss";
import "./styles/admin/UserReport.scss";
import "./styles/admin/UserDetails.scss";
import "./styles/admin/Product.scss";
import "./styles/admin/Orders.scss";
import "./styles/admin/Settings.scss";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const { appData } = useAppData();

  const { isLoggedIn, userType } = appData;

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/admin/login"
            element={
              <ProtectedRoute
                element={<AdminLogin />}
                redirectTo="/dashboard"
              />
            }
          />
          <Route
            path="/login"
            element={<ProtectedRoute element={<Login />} redirectTo="/home" />}
          />
          <Route
            path="/signup"
            element={<ProtectedRoute element={<SignUp />} redirectTo="/home" />}
          />

          {userType === "Admin" && (
            <Route path="/*" element={<AdminRouter />} />
          )}
          {userType === "Business" && (
            <Route path="/*" element={<BusinessRouter />} />
          )}

          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
