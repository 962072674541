import React from "react";
import Footer from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./components/admin/Home";
import Header from "./components/business/Headers";
import Products from "./components/admin/Products";
import Orders from "./components/admin/Orders";
import Settings from "./components/admin/Settings";
import PendingReport from "./components/admin/PendingReport";
import UserReport from "./components/admin/UserReport";
import UserDetails from "./components/admin/UserDetails";
import AddProduct from "./components/admin/AddProduct";

function AdminRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/dashboard" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/reports" element={<PendingReport />} />
        <Route path="/u-reports" element={<UserReport />} />
        <Route path="/user" element={<UserDetails />} />
        <Route path="/add-product" element={<AddProduct />} />
      </Routes>
      <Footer />
    </>
  );
}

export default AdminRouter;
