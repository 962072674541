import { apiClient } from "./configs/axiosConfig";

export const getOrdersAccordingToMedId = async (data) => {
  let responseToBeSent;
  await apiClient
    .get(`/getOrders?medicineId=${data}`)
    .then((res) => {
      // console.log("/medfind", res);
      responseToBeSent = res?.data?.orders;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getOrdersAccordingToStatus = async (status) => {
  let responseToBeSent;
  await apiClient
    .get(`/getOrders?status=${status}`)
    .then((res) => {
      // console.log("/medfind", res);
      responseToBeSent = res?.data?.orders;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getPendingOrderscompoandTotal = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/pendingReport`)
    .then((res) => {
      responseToBeSent = res?.data?.orders;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
export const getUsersTotalAmt = async (data) => {
  let responseToBeSent;
  await apiClient
    .get(`/pendingReport?userId=${data}`)
    .then((res) => {
      responseToBeSent = res?.data?.orders;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getPendingOrderscomp = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/pendingComposition`)
    .then((res) => {
      // console.log("/medfind", res);
      responseToBeSent = res?.data?.aggregatedOrders;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getOrdersAccordingToUserId = async (data) => {
  let responseToBeSent;
  await apiClient
    .get(`/getOrders?userId=${data}`)
    .then((res) => {
      // console.log("/medfind", res);
      responseToBeSent = res?.data?.orders;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const editOrderStatus = async (data) => {
  let responseToBeSent;
  await apiClient
    .put("/editorders", data)
    .then((res) => {
      // console.log("/medfind", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};

export const postOrder = async (data) => {
  let responseToBeSent;
  await apiClient
    .post("/order", data)
    .then((res) => {
      // console.log("/medfind", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};
