import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  VStack,
  Text,
  Stack,
  Skeleton,
  Heading,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa6";
import {
  SubCatStatus,
  getAllCategory,
  postCategory,
} from "../../apis/productApis";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import EditCategoryForm from "./Editcatform";

const Category = () => {
  const [category, setCategory] = useState([]);
  const [selectcat, setSelectCat] = useState();
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const onCloseModal1 = () => setIsOpenModal1(false);
  const onOpenModal1 = () => setIsOpenModal1(true);
  const [isaddModal, setIsaddModal] = useState(false);
  const onCloseAddModal = () => setIsaddModal(false);
  const onaddModal = () => setIsaddModal(true);
  async function fetchData() {
    setloading(false);
    const cat = await getAllCategory();
    if (cat) {
      setloading(true);
      setCategory(cat);
    }
    setloading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);
  const [formData, setFormData] = useState({
    name: "",
    subcategories: [{ name: "" }],
  });
  const [loading, setloading] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e, index) => {
    const { value } = e.target;
    const updatedSubcategories = [...formData.subcategories];
    updatedSubcategories[index] = { name: value };
    setFormData({
      ...formData,
      subcategories: updatedSubcategories,
    });
  };
  const handleAddSubcategory = () => {
    setFormData({
      ...formData,
      subcategories: [...formData.subcategories, { name: "" }],
    });
  };
  const handleRemoveSubcategory = (index) => {
    const updatedSubcategories = formData.subcategories.filter(
      (_, idx) => idx !== index,
    );
    setFormData({
      ...formData,
      subcategories: updatedSubcategories,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setloading(true);
    let req = await postCategory(formData);
    if (req.message === "Category added successfully") {
      onCloseAddModal();
      fetchData();
      setFormData({
        name: "",
        subcategories: [{ name: "" }],
      });
    } else {
      setError(req.message);
      setloading(false);
    }
  };
  const handleMenuItemClick = async (item, activeValue) => {
    const data = {
      subId: item?._id,
      name: item?.name,
      active: activeValue,
    };
    console.log(data);
    setloading(true);
    const req = await SubCatStatus(data);
    // console.log(req);
    if (req.message === "Subcategory status updated successfully") {
      fetchData();
    } else {
      setError(req.message);
      setloading(false);
    }
  };
  return (
    <div className="TabContainer">
      <Card>
        <CardBody>
          <HStack
            style={{
               display: "flex",
               justifyContent:"space-between",
               alignItems:"center",
               marginBottom:"1rem"
            }}
          >
             <Heading>Category</Heading>
            <Button className="button" onClick={onaddModal}>
              + ADD
            </Button>
          </HStack>
          {loading ? (
            <Stack>
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
            </Stack>
          ) : (
            <Accordion allowToggle>
              {category.length > 0
                ? category.map((item) => (
                    <AccordionItem key={item?._id} onClick={() => {}}>
                      <h2>
                        <AccordionButton>
                          <MdEdit
                            size={"20px"}
                            style={{ marginRight: "10px" }}
                            color={"#2d2d2d"}
                            onClick={() => {
                              setSelectCat(item);
                              onOpenModal1(true);
                            }}
                          />
                          <Box as="span" flex="1" textAlign="left">
                            {item?.name}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {item?.subcategories.map((category) => (
                          <HStack key={category?._id} className="list-Item">
                            <p>{category?.name}</p>
                            <Menu>
                              <MenuButton
                                backgroundColor={
                                  category.active ? "#2E8B57aa" : "#FFCCCB"
                                }
                                as={Button}
                                rightIcon={<ChevronDownIcon />}
                                style={{ width: 120 }}
                              >
                                {category.active ? "Active" : "Inactive"}
                              </MenuButton>
                              <MenuList>
                                {!category.active ? (
                                  <MenuItem
                                    onClick={() =>
                                      handleMenuItemClick(category, true)
                                    }
                                  >
                                    Activate
                                  </MenuItem>
                                ) : (
                                  <MenuItem
                                    onClick={() =>
                                      handleMenuItemClick(category, false)
                                    }
                                  >
                                    Deactivate
                                  </MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          </HStack>
                        ))}
                      </AccordionPanel>
                    </AccordionItem>
                  ))
                : null}
            </Accordion>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        isCentered
        size={"lg"}
      >
        <ModalOverlay>
          <EditCategoryForm
            category={selectcat}
            fetchData={fetchData}
            onCloseModal1={onCloseModal1}
          />
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={isaddModal}
        onClose={onCloseAddModal}
        isCentered
        size={"lg"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Add Category</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Category Name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    required
                  />
                  <Text onClick={handleAddSubcategory}>Sub Categories</Text>
                  {formData.subcategories.map((subcategory, index) => (
                    <HStack key={index}>
                      <FaPlus onClick={handleAddSubcategory} />
                      <Input
                        type="text"
                        name="subcategory"
                        placeholder="Subcategory Name"
                        value={subcategory.name}
                        onChange={(e) => handleChange(e, index)}
                        required
                      />
                      {/* {index !== 0 && ( */}
                      <RxCross2
                        onClick={() => handleRemoveSubcategory(index)}
                      />
                      {/* )} */}
                    </HStack>
                  ))}
                  {error && <Text color="red">{error}</Text>}
                  <Button type="submit">Add Category</Button>
                </VStack>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </div>
  );
};

export default Category;
