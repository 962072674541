import { apiClient } from "./configs/axiosConfig";

export const get_all_users = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/getUsers`)
    .then((res) => {
      // console.log("/medfind", res);
      responseToBeSent = res?.data?.users;
    })
    .catch((err) => {
      console.log("Error in get all users ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const findUser = async (data) => {
  //   console.log(data);
  let responseToBeSent;
  await apiClient
    .get(`/searchUser?searchTerm=${data}`)
    .then((res) => {
      //   console.log("/searchUser", res);
      responseToBeSent = res?.data?.users;
    })
    .catch((err) => {
      console.log("Error in fining user ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getUserDetails = async (data) => {
  let responseToBeSent;
  await apiClient
    .get(`/user/${data}`)
    .then((res) => {
      responseToBeSent = res?.data?.users;
    })
    .catch((err) => {
      console.log("Error in getting  user ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const blockUser = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/blockUser`, data)
    .then((res) => {
      responseToBeSent = res?.data?.message;
    })
    .catch((err) => {
      console.log("Error in getting  user ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};

export const ChangeAdminPassword = async (data) => {
  let responseToBeSent;
  await apiClient
    .put(`/change-password`, data)
    .then((res) => {
      responseToBeSent = res?.data?.message;
    })
    .catch((err) => {
      console.log("Error in getting  user ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};
