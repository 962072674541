import React, { useEffect } from "react";
import Header from "./business/Headers";
import { Link, useLocation } from "react-router-dom";

const Home = () => {
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    if (location.pathname === "/login") {
      localStorage.setItem("userType", "Business");
    }
  }, []);

  return (
    <div className="firstScreen">
      <Header />
      <main>
        <h1>Welcome to Cermax Order Booking</h1>

        <section id="hero">
          <h2>Welcome to our Medical Product Delivery App</h2>
          <p>Efficiently deliver medical supplies right to your doorstep.</p>
        </section>

        <section id="about">
          <h2>About Us</h2>
          <p>
            We are a medical product delivery app that aims to provide
            convenient and reliable delivery services for all your medical
            needs.
          </p>
          <p>
            Our platform connects pharmacies, hospitals, and medical suppliers
            with customers, ensuring timely delivery of essential medical
            products.
          </p>
        </section>

        <section id="features">
          <h2>Key Features</h2>
          <ul>
            <li>Easy registration process</li>
            <li>Secure payment options</li>
            <li>Real-time tracking of deliveries</li>
            <li>Wide range of medical products available</li>
          </ul>
        </section>

        <section id="contact">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or feedback, feel free to reach out to us.
          </p>
          <p>
            Email:{" "}
            <a
              href="#"
              onClick={() => handleEmailClick("sales@cermaxhealthcare.com")}
            >
              sales@cermaxhealthcare.com
            </a>
          </p>
        </section>
      </main>
    </div>
  );
};

export default Home;
