import React, { useState } from "react";
import {
  Input,
  HStack,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
} from "@chakra-ui/react";
import { RxCross2 } from "react-icons/rx";
import { EditCategory } from "../../apis/productApis";

const EditCategoryForm = ({ category, fetchData, onCloseModal1 }) => {
  //   console.log(onCloseModal1());
  const [formEditData, setFormEditData] = useState({
    _id: category._id,
    name: category.name,
    subcategories: category.subcategories.map((subcat) => ({
      subcatId: subcat._id,
      subcatName: subcat.name,
    })),
  });
  const [err, seterr] = useState("");

  const handleEditChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSubcategories = [...formEditData.subcategories];
    updatedSubcategories[index][name] = value;
    setFormEditData({
      ...formEditData,
      subcategories: updatedSubcategories,
    });
  };

  const handleEditAddSubcategory = () => {
    setFormEditData({
      ...formEditData,
      subcategories: [...formEditData.subcategories, { subcatName: "" }],
    });
  };

  const handleEditRemoveSubcategory = (index) => {
    const updatedSubcategories = [...formEditData.subcategories];
    updatedSubcategories.splice(index, 1);
    setFormEditData({
      ...formEditData,
      subcategories: updatedSubcategories,
    });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    // console.log("formData", formEditData);
    try {
      const req = await EditCategory(formEditData);
      if (req.message === "Category updated successfully") {
        await fetchData();
        onCloseModal1();
      } else {
        seterr(req.message);
        onCloseModal1();
      }
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  return (
    <ModalContent>
      <ModalHeader>Edit Category Name</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <form onSubmit={handleEditSubmit} className="categoryForm">
          <div className="categorydiv">
            <label className="categoryLable">Category Name</label>
            <Input
              type="text"
              name="name"
              value={formEditData.name}
              onChange={(e) =>
                setFormEditData({ ...formEditData, name: e.target.value })
              }
              required
            />
          </div>
          <div className="categorydiv">
            <label className="categoryLable">Sub Categories</label>
          </div>
          {formEditData.subcategories.map((subcat, index) => (
            <HStack key={index} m={1}>
              <button type="button" onClick={handleEditAddSubcategory}>
                +
              </button>
              <Input
                type="text"
                name="subcatName"
                value={subcat.subcatName}
                placeholder="Sub category"
                onChange={(e) => handleEditChange(index, e)}
                required
              />
              <button
                type="button"
                onClick={() => handleEditRemoveSubcategory(index)}
              >
                <RxCross2 />
              </button>
            </HStack>
          ))}
          {err && <Text color="red">{err}</Text>}
          <button type="submit" className="categoryButton">
            Submit
          </button>
        </form>
      </ModalBody>
    </ModalContent>
  );
};

export default EditCategoryForm;
