import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  addPreparation,
  editPreparation,
  getAllPreparation,
} from "../../apis/productApis";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

const PreparationType = () => {
  const [Preparation, setPreparation] = useState([]);
  const [loading, setloading] = useState(false);
  const [isaddModal, setIsaddModal] = useState(false);
  const [errText, seterrText] = useState(false);
  const onCloseAddModal = () => setIsaddModal(false);

  const onaddModal = () => setIsaddModal(true);

  const [isEditModal, setIsEditModal] = useState(false);
  const onCloseEditModal = () => setIsEditModal(false);

  const onEditModal = () => setIsEditModal(true);
  async function fetchData() {
    setloading(true);
    const Preparation = await getAllPreparation();

    if (Preparation) {
      setPreparation(Preparation);
      setloading(false);
    }
    setloading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    active: true,
  });
  const [types, setTypes] = useState([{ name: "" }]);

  const handleChange = (index, value) => {
    const updatedTypes = [...types];
    updatedTypes[index] = { name: value };
    setTypes(updatedTypes);
    seterrText("");
  };

  const handleAddType = () => {
    setTypes([...types, { name: "" }]);
  };

  const handleRemoveType = (index) => {
    if (types.length === 1) return;
    const updatedTypes = [...types];
    updatedTypes.splice(index, 1);
    setTypes(updatedTypes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    const data = { types: types };
    const req = await addPreparation(data);
    // console.log(req);
    if (req.message === "Preparation types added successfully") {
      onCloseAddModal();
      fetchData();
      setTypes([{ name: "" }]);
    } else {
      seterrText(req.message);
      setloading(false);
    }
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    seterrText("");
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    // console.log("called", formData);
    const data = {
      id: formData._id,
      name: formData.name,
      active: formData.active,
    };
    setloading(true);
    const req = await editPreparation(data);
    // console.log(req);
    if (req.message === "Preparation type updated successfully") {
      onCloseEditModal();
      fetchData();
    } else {
      seterrText(req.message);
      setloading(false);
    }
  };

  const handleMenuItemClick = async (item, activeValue) => {
    const data = {
      id: item?._id,
      name: item?.name,
      active: activeValue,
    };
    setloading(true);
    const req = await editPreparation(data);
    // console.log(req);
    if (req.message === "Preparation type updated successfully") {
      onCloseEditModal();
      fetchData();
    } else {
      seterrText(req.message);
      setloading(false);
    }
  };
  return (
    <div className="TabContainer">
      <Card>
        <CardBody>
          <HStack
            style={{
              display: "flex",
              justifyContent:"space-between",
              alignItems:"center",
              marginBottom:"1rem"
            }}
          >
            <Heading>Preparation Types</Heading>
            <Button className="button" onClick={onaddModal}>
              + ADD
            </Button>
          </HStack>
          {loading ? (
            <Stack>
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
            </Stack>
          ) : (
            <VStack>
              {Preparation?.length > 0 &&
                Preparation.map((item) => (
                  <HStack
                    key={item?._id}
                    className="list-Item"
                    onClick={() => {
                      setFormData(item);
                    }}
                  >
                    <Stack flexDirection={"row"}>
                      <MdEdit
                        size={"20px"}
                        style={{ marginRight: "10px" }}
                        color={"#2d2d2d"}
                        onClick={() => {
                          setFormData(item);
                          onEditModal(true);
                        }}
                      />
                      <p>{item?.name}</p>
                    </Stack>
                    <Menu>
                      <MenuButton
                        backgroundColor={item.active ? "#2E8B57aa" : "#FFCCCB"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        style={{ width: 120 }}
                        onClick={() => {
                          // console.log(formData);
                        }}
                      >
                        {item.active ? "Active" : "Inactive"}
                      </MenuButton>
                      <MenuList>
                        {!item.active ? (
                          <MenuItem
                            onClick={() => handleMenuItemClick(item, true)}
                          >
                            Activate
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => handleMenuItemClick(item, false)}
                          >
                            Deactivate
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </HStack>
                ))}
            </VStack>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isaddModal}
        onClose={onCloseAddModal}
        isCentered
        size={"lg"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Add Preparation Type</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <form className="categoryForm" onSubmit={handleSubmit}>
                <VStack>
                  {types.map((type, index) => (
                    <HStack key={index}>
                      <FaPlus onClick={handleAddType} />
                      <Input
                        type="text"
                        value={type.name}
                        placeholder="Type Name"
                        onChange={(e) => handleChange(index, e.target.value)}
                      />
                      {index !== 0 && (
                        <Button
                          type="button"
                          onClick={() => handleRemoveType(index)}
                        >
                          <RxCross2 />
                        </Button>
                      )}
                    </HStack>
                  ))}
                </VStack>
                {errText.length > 0 && <Text color={"red"}>{errText}</Text>}
                <button className="categoryButton" type="submit">
                  Add
                </button>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={isEditModal}
        onClose={onCloseEditModal}
        isCentered
        size={"lg"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Edit Preparation Type</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <form className="categoryForm" onSubmit={handleEditSubmit}>
                <HStack>
                  <div className="categorydiv">
                    <label className="categoryLable">Preparation Type</label>
                    <Input
                      className="categoryInput"
                      type="text"
                      required
                      name="name"
                      placeholder="Preparation Type"
                      value={formData.name}
                      onChange={handleEditChange}
                    />
                  </div>
                </HStack>
                {errText.length > 0 && <Text color={"red"}>{errText}</Text>}
                <button className="categoryButton" type="submit">
                  Edit
                </button>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </div>
  );
};

export default PreparationType;
