import React from "react";

const Footer = () => {
  return (
    <footer>
      <div>
        <a href="https://cermaxhealthcare.com/">
          Cermax Health Care
          <span> @all right reserved</span>
        </a>
        {/* <p> </p> */}
      </div>
      <div>{/* <h3>Follow us</h3> */}</div>
    </footer>
  );
};

export default Footer;
