function generateInvoice(order, user) {
  console.log("inside pdf", order, user);
  const orderDate = new Date(order.orderDate);
  const formattedDate = orderDate.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
  let html = `<html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Bill</title>
              <style>
                  .pdfBody{
                        margin:1rem 2rem;
                  }
                  .headerBox{
                    padding-top:2rem;
                    text-align:center;
                  }
                    .headerBox h5{
                    font-weight:700!important;
                    font-size:0.8rem;
                    }
                  .bill-container {
                      border: 1px solid #000;
                      /* padding: 20px; */
                      width: 100%;
                      margin: 0 auto;
                  }
                   .bill-container p {
                      font-size: 14px;
                   }
          
                  .bill-header {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                  }
          
                  .icon {
                      width: 150px;
                      height: 150px;
                  }
                  .bill-for{
                      text-align: left;
                      width:50%!important;
                  }
                  .bill-for h3{
                     font-size:12px;
                    font-weight: bold;
                    
                  }
                    .bill-for-or{
                     font-size:0.8rem;
                     font-weight: 600;
                     margin-left:9px;
                    }
                       .bill-for h2{
                     font-size:15px;
                    font-weight: bold;
                    margin-bottom:0.3rem;
                  }
                  .bill-dates{
                      text-align:end;
                  }
          
                  .bill-address {
                      text-align: right;
                      padding-right: 8px;
                      padding-top:0!important;
                      display:flex;
                      flex-direction:column;
                      gap:0.1rem;
                      justify-content:flex-end;
                  }
                  .bill-section {
                     
                      display: flex;
                      justify-content: space-between;
                      /* margin-bottom: 20px; */
                  }
          
               
              
             .itemTh {
            width: 10%!important; /* Custom width for item name */
        }
         
 .bill-table {
    border-collapse: collapse; /* Merge borders between cells */
    width: 100%; /* Ensure table takes full width */           
    margin-top: 20px;
}

.bill-table td, .bill-table th {
    border: 1px solid #000; /* Border color and width */
    padding: 8px; /* Padding inside cells */
    text-align: left; /* Align text to the left */
    font-size: 10px; /* Font size */
    max-width: 200px; /* Maximum width of cells */
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
    white-space:wrap; /* Prevent text from wrapping */
}

.bill-table th {
    font-weight: bold; /* Optional: Make header text bold */
}


.bill-table td{font-weight:500}
                   .hr{
                     border-top: 0.5px solid #000;
                     height:0.1rem;
                   }
                  .bill-total {
                      font-weight: bold;
                  }
                      .headLine{
                      font-weight:700
                      }
                      p{
                      font-size:10px!important;
                      font-weight:500;
                      }
                      .billOdetails{
                      display:flex;
                      flex-direction:column;
                      }
                    .billFromInvoice{
                      display:flex;
                      align-items: center;
                      justify-content:space-between;
                      background:rgba(0,0,0,0.5);
                      color:#FFF;
                      padding:10px;
                    }
                      .billFromInvoice div{
                      margin:0!important;
                      padding:0!important;
                      }
                      .bill-Details{
                      padding:8px!important;
                      display:flex;
                      justify-content:space-between;
                      }
                     .last-Summary{
                     padding:10px;
                     }
                          .last-Summary .bill-for h4{
                           font-size: 12px;
                           font-weight:600;
                          }
                            .last-Summary .bill-for p{
                           margin-top:8px;
                          }
              </style>
          </head>
          <body>
                   <div class="headerBox">
                      <h5>Porforma Invoice</h5>
               
                     </div>
              <div class="pdfBody">
              <div class="bill-container">
                    
                     
                  <div class="bill-header">
                      <img src="https://cermaxhealthcare.com/logo.jpg" alt="Icon" class="icon">
                      <div class="bill-address">
                          <h4 class="headLine">Cermax Healthcare </h4>
                          <p>Ground Floor 499-C 1/11th Cross Road, Bengaluru</p>
                          <p>9972065757 , cermaxhealthcare@gmail.com</p>
                          <p>GSTIN : 29ADCPB7919E1ZO, State 29-Karnataka</p>
                          <p>Drug License No : KA-B41-20B 235830/ 21B 235831</p>
                      </div>
                  </div>
                    <hr class="hr"/>
                  <div class="billOdetails">
               
                     <div class="bill-Details">
                        
                      <div class="bill-for">
                       <h2>Bill For:</h2>
                          <h3>${user.name}</h3>
                          <p>Address: ${user.address}</p>
                          <p>Contact No. : ${user.mobile}</p>
                          <p>GSTIN/ MCI : ${
                            user.gstIn ? user.gstIn : user?.mci
                          }</p>
                          <p>State: ${user.state ? user.state : "-"}</p>
                      </div>
                        <div class="bill-dates">
                          <p>Order No: ${order._id}</p>
                          <p>Date: ${formattedDate}</p>
                        <p>Place of Supply: 29-Karnataka</p>
                        </div>
                     </div>
                      `;

  html += `
                    
                  </div>
                  <table class="bill-table">
                      <thead class="table-head">
                          <tr>
                      

                           <th >#</th>
                          <th class="itemTh">Item Name</th>
                          <th >HSN</th>
                          <th >MRP</th>
                          <th >Packing</th>
                          <th >Quantity</th>
                          <th>P/U</th>
                          <th >Taxable<br/>Amount</th>
                          <th >GST</th>
                          <th >Amount</th>
                          </tr>
                      </thead>
                      <tbody>`;

  order?.products?.forEach((product, idx) => {
    html += `<tr>
                          <td >${idx + 1}</td>
                          <td  class="itemTh">${product.name} | ${product.composition}</td>
                          <td >${
                            product?.hsn_code ? product?.hsn_code : "-"
                          }</td>
                          <td >${product.mrp}</td>
                          <td >${product.packing_type}</td>
                          <td >${product.quantity}</td>
                          <td >${product.per_unit ? product.per_unit : "-"}</td>
                          <td >${product.tax_amount}</td>
                          <td >${product.gst_amount}<small>(${
      product.gst_percentage
    })%</small></td>
                          <td>${product.amount}</td>
                        </tr>`;
  });

  html += `
                              </tbody>
                              <tfoot>
                                  <tr class="bill-table">
                                      <td></td>
                                      <td><b>Total</b></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td><b>${order.total_quantity}</b></td>
                                      <td></td>
                                      <td><b>₹ ${order.total_tax_amt}</b></td>
                                      <td><b>₹ ${order.total_gst}</b></td>
                                      <td><b>₹ ${order.total_amount}</b></td>
                                  </tr>
                              </tfoot>
                          </table>
                          <div class="bill-section">
              <div class="bill-for-or"> Order Summary : </div>
              <div class="bill-dates">
                  <table class="bill-table">
                  <tr><td>Taxable Amount  </td><td>${order.total_tax_amt}</td></tr>`;

  html += `${
    order.gst_type == "IGST"
      ? `<tr><td>IGST</td><td>${order.total_gst}</td></tr>`
      : `<tr><td>CGST</td><td>${order.total_gst / 2}</td></tr>` +
        `<tr><td>SGST</td><td>${order.total_gst / 2}</td></tr>`
  }`;

  html += `               
                      <tr><td>Total Amount</td><td>₹ ${order.total_amount}</td></tr>
                  </table>
              </div>
              </div>

              <hr class="hr" style="margin-top:10px"/>
                          <div class="bill-section last-Summary">
                      <div class="bill-for">
                          <h4>Terms and Conditions</h4>
                          <p>Thanks for Doing business with us</p>
                          
                      </div>
                      <div class="bill-dates">
                          <p>For, cermax Healthcare </p>
                          <br/>
                          <br/>     
                          <p>Authorized Signatory</p>
                      </div>
                  </div>
                          </div>
              </div>
              
           </body>
           </html>`;

  return html;
}

module.exports = { generateInvoice };
