import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// Import CSS for styling
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { ChangeAdminPassword } from "../../apis/UserApis";
import { useAppData } from "../../hooks/AppDataContext";

const SideDrawer = () => {
  const { logout } = useAppData();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
  });

  const onOpen = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const onClose = () => {
    setIsOpen(false);
  };
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = await JSON.parse(localStorage.getItem("user"));
    const data = {
      userId: user?._id,
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
    };
    try {
      const response = await ChangeAdminPassword(data);
      console.log(response);
      logout();

      setFormData({
        currentPassword: "",
        newPassword: "",
      });
      setError("");
      onClose();
      navigate("/admin/login");
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError("Internal Server Error");
      }
    }
  };

  return (
    <div className={`side-drawer open`}>
      <Link
        to="/dashboard"
        className={location.pathname === "/dashboard" ? "active" : ""}
      >
        Home
      </Link>
      <Link
        to="/reports"
        className={location.pathname === "/reports" ? "active" : ""}
      >
        Pending Report
      </Link>
      <Link
        to="/u-reports"
        className={location.pathname === "/u-reports" ? "active" : ""}
      >
        User Report
      </Link>
      <Link onClick={() => onOpen()}>Change Password</Link>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormControl isRequired>
                <FormLabel>Current Password</FormLabel>
                <Input
                  type="password"
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                />
              </FormControl>
              {error && <FormErrorMessage>{error}</FormErrorMessage>}
              <Button mt={4} colorScheme="blue" type="submit">
                Change Password
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SideDrawer;
