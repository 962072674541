import React, { useState } from "react";
import vg from "../../assets/crop.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { AdminLoginApi, forgetPassword } from "../../apis/authApis";
import { useAppData } from "../../hooks/AppDataContext";
import { useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const navigate = useNavigate();
  const { updateAppData } = useAppData();
  const [isOpenModal1, setIsOpenModal1] = useState(false);

  const onCloseModal1 = () => setIsOpenModal1(false);

  const onOpenModal1 = () => setIsOpenModal1(true);
  const [password, setPaswword] = useState("");
  const [email, setEmail] = useState("");
  const [verificationCode, seVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const Login = async (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password,
    };
    const req = await AdminLoginApi(data);

    if (req?.user?._id) {
      await updateAppData({ user: req?.user, userType: req?.user.userType });

      console.log(req?.user);
      await localStorage.setItem("userType", "Admin");
      await localStorage.setItem("user", JSON.stringify(req?.user));

      await navigate("/dashboard");
    } else {
      // console.log(req.response.data.error);
      setError(req.response.data.error);
    }
  };

  const handleForgotPassword=async()=>{
    const data = {
      email: "admin@cerdoc.com",
    };
    const req = await forgetPassword(data);
    if(req.message==="Password reset code sent successfully")
    {
      console.log("req succjmiuoje");
      onOpenModal1();
    }
    
  }

  return (
    <div className="loginContainer">
      <main>
        <h1>Welcome to Cermax Order Booking</h1>
        <form onSubmit={Login}>
          <div>
            <label>Email</label>
            <input
              type="email"
              required
              placeholder="abc@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              required
              onChange={(e) => setPaswword(e.target.value)}
              value={password}
            />
          </div>
          {error && <Text style={{ color: "red" }}>{error}!</Text>}

          <button type="submit">Signin</button>
          <p className="forgetPass"
            onClick={handleForgotPassword}
          >
            Forgot Password ?
          </p>
        </form>
      </main>
      <div>
        <img src={vg} alt="Graphics" />
      </div>
      <Modal
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        isCentered
        size={"xl"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />

            <ModalBody style={{padding:"20px", fontWeight:500 }}>
              We have received a request to reset your password for your
              account.  Your password has been sent to your registered
              email address.
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </div>
  );
};

export default AdminLogin;
