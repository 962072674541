import axios from "axios";
import envConfig from "./envConfig";

export const apiClient = axios.create({
  baseURL: envConfig.baseUrl_node,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
