import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  HStack,
  Input,
  Select,
  Button,
  VStack,
  Heading,
  useDisclosure,
  Alert,
  AlertIcon,
  Box,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import {
  addproducts,
  getAllCategory,
  getAllComposition,
  getAllPackingType,
  getAllPreparation,
  getAllgst,
} from "../../apis/productApis";
import { DownloadIcon } from "@chakra-ui/icons";
import { FaFileUpload } from "react-icons/fa";
import { apiClient } from "../../apis/configs/axiosConfig";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen: isVisible, onClose, onOpen } = useDisclosure();
  const [category, setCategory] = useState([]);
  const [composition, setComposition] = useState([]);
  const [packing, setPacking] = useState([]);
  const [prepType, setPrepType] = useState([]);
  const [gstType, setGstType] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handelUploadRx = async (event) => {
    setFile(event.target.files[0]);
    if (!event.target.files[0]) {
      setMessage("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("uploadfile", event.target.files[0]);

    try {
      const response = await apiClient.post("/uploadExcelFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage(response.data.message);
      toast({
        title: "Upload Successful.",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      navigate("/products");
    } catch (error) {
      setMessage("Error uploading file.");
      console.error("Error uploading file:", error);
      toast({
        title: "Error.",
        description: "Error uploading file.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
    }
  };

  async function fetchData() {
    try {
      // Fetch data
      const cat = await getAllCategory();
      const comp = await getAllComposition();
      const packing = await getAllPackingType();
      const preprationType = await getAllPreparation();
      const gsties = await getAllgst();

      if (cat) setCategory(cat.filter((item) => item.active));
      if (comp) setComposition(comp.filter((item) => item.active));
      if (packing) setPacking(packing.filter((item) => item.active));
      if (preprationType)
        setPrepType(preprationType.filter((item) => item.active));
      if (gsties) setGstType(gsties.filter((item) => item.active));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    medId: "",
    name: "",
    composition: "",
    product_category: "",
    sub_category: "",
    packing_type: "",
    num_pack_value: "",
    preparation_type: "",
    shipper_pack_qty: "",
    batch_qty: "",
    batch_price: "",
    Moq: "",
    hsn_code: "",
    gst_percentage: "",
    mrp: "",
    active: "",
  });

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      if (name === "composition") {
        const selectedComposition = composition.find(
          (item) => item._id === value,
        );
        const newMoq = selectedComposition ? selectedComposition.moq : "";
        return {
          ...prevState,
          [name]: value,
          Moq: newMoq,
        };
      }
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const EditProduct = async () => {
    const req = await addproducts(formData);
    if (req.message === "Medicine added successfully") {
      onOpen();
      setFormData({
        medId: "",
        name: "",
        composition: "",
        product_category: "",
        sub_category: "",
        packing_type: "",
        num_pack_value: "",
        preparation_type: "",
        shipper_pack_qty: "",
        batch_qty: "",
        batch_price: "",
        Moq: "",
        hsn_code: "",
        gst_percentage: "",
        mrp: "",
        active: "",
      });
    }
    navigate("/products");
  };

  const convertNameToCSV = () => {
    const csvData = [];
    csvData.push([
      "S No.",
      "name",
      "composition",
      "product_category",
      "sub_category",
      "packing_type",
      "num_pack_value",
      "preparation_type",
      "shipper_pack_qty",
      "batch_qty",
      "batch_price",
      "Moq",
      "per1xMoq",
      "per2xMoq",
      "per3xMoq",
      "per4xMoq",
      "per5xMoq",
      "hsn_code",
      "gst_percentage",
      "mrp",
    ]);
    return csvData;
  };

  return (
    <div className="Products">
      <Card style={{ width: "100%" }}>
        {isVisible ? (
          <Alert status="success">
            <AlertIcon />
            <Box>
              <AlertTitle>Success!</AlertTitle>
              <AlertDescription>Product added successfully!!!</AlertDescription>
            </Box>
            <CloseButton
              alignSelf="flex-start"
              position="relative"
              right={-1}
              top={-1}
              onClick={onClose}
            />
          </Alert>
        ) : null}
        <CardBody>
          <HStack style={{ justifyContent: "space-between" }}>
            <Heading as="h3" size="lg">
              Add Product
            </Heading>
            <VStack>
              <div className="profile-pic-upload">
                Bulk Upload <FaFileUpload style={{ marginLeft: "5px" }} />
                <input
                  type="file"
                  id="profile_image"
                  name="profile_image"
                  onChange={handelUploadRx}
                />
              </div>
              <CSVLink
                data={convertNameToCSV()}
                filename={"sample_product.csv"}
                className="downloadButton1"
              >
                Sample CSV <DownloadIcon style={{ marginLeft: "15px" }} />
              </CSVLink>
            </VStack>
          </HStack>

          <HStack
            style={{
              justifyContent: "space-around",
              padding: "1rem",
            }}
          >
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Product Name<span>*</span>
              </label>
              <Input
                name="name"
                value={formData.name}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Product Name"
              />
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Category<span>*</span>
              </label>
              <Select
                name="product_category"
                value={formData.product_category}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Select Category"
              >
                {category.length > 0 &&
                  category.map((item) => (
                    <option value={item?._id} key={item?._id}>
                      {item?.name}
                    </option>
                  ))}
              </Select>
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Sub Category<span>*</span>
              </label>
              <Select
                name="sub_category"
                value={formData.sub_category}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Select Sub Category"
              >
                {formData.product_category
                  ? category
                      .find(
                        (category) =>
                          category._id === formData.product_category,
                      )
                      .subcategories.filter((subcategory) => subcategory.active)
                      .map((subcategory) => (
                        <option key={subcategory._id} value={subcategory._id}>
                          {subcategory.name}
                        </option>
                      ))
                  : null}
              </Select>
            </VStack>
          </HStack>
          <HStack
            style={{
              justifyContent: "space-around",
              padding: "1rem",
            }}
          >
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Composition<span>*</span>
              </label>
              <Select
                name="composition"
                value={formData.composition}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Select Composition"
              >
                {composition
                  .filter(
                    (item) => item.subcategory_id === formData.sub_category,
                  )
                  .map((item) =>
                    item.active ? (
                      <option value={item?._id} key={item?._id}>
                        {item?.name}
                      </option>
                    ) : null,
                  )}
              </Select>
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Packing Type<span>*</span>
              </label>
              <Select
                name="packing_type"
                value={formData.packing_type}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Select Packing Type"
              >
                {packing.map((item) => (
                  <option value={item?._id} key={item?._id}>
                    {item?.type}
                  </option>
                ))}
              </Select>
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Number of Packs<span>*</span>
              </label>
              <Input
                name="num_pack_value"
                value={formData.num_pack_value}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Number of Packs"
              />
            </VStack>
          </HStack>
          <HStack
            style={{
              justifyContent: "space-around",
              padding: "1rem",
            }}
          >
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Preparation Type<span>*</span>
              </label>
              <Select
                name="preparation_type"
                value={formData.preparation_type}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Select Preparation Type"
              >
                {prepType.map((item) => (
                  <option value={item?._id} key={item?._id}>
                    {item?.name}
                  </option>
                ))}
              </Select>
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Shipper Pack Qty<span>*</span>
              </label>
              <Input
                name="shipper_pack_qty"
                value={formData.shipper_pack_qty}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Shipper Pack Qty"
              />
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Batch Qty<span>*</span>
              </label>
              <Input
                name="batch_qty"
                value={formData.batch_qty}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Batch Qty"
              />
            </VStack>
          </HStack>
          <HStack
            style={{
              justifyContent: "space-around",
              padding: "1rem",
            }}
          >
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                Batch Price<span>*</span>
              </label>
              <Input
                name="batch_price"
                value={formData.batch_price}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Batch Price"
              />
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                MOQ<span>*</span>
              </label>
              <Input
                name="Moq"
                value={formData.Moq}
                disabled
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="MOQ"
              />
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                HSN Code<span>*</span>
              </label>
              <Input
                name="hsn_code"
                value={formData.hsn_code}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="HSN Code"
              />
            </VStack>
          </HStack>
          <HStack
            style={{
              justifyContent: "space-around",
              padding: "1rem",
            }}
          >
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                GST Percentage<span>*</span>
              </label>
              <Select
                name="gst_percentage"
                value={formData.gst_percentage}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="Select GST Percentage"
              >
                {gstType.map((item) => (
                  <option value={item?._id} key={item?._id}>
                    {item?.name}%
                  </option>
                ))}
              </Select>
            </VStack>
            <VStack style={{ display: "flex", width: "30%" }}>
              <label className="label">
                MRP<span>*</span>
              </label>
              <Input
                name="mrp"
                value={formData.mrp}
                onChange={handleEditInputChange}
                style={{ color: "black" }}
                _placeholder={{ color: "black" }}
                placeholder="MRP"
              />
            </VStack>
          </HStack>
          <HStack
            style={{
              justifyContent: "space-around",
              padding: "1rem",
            }}
          >
            <Button
              onClick={() => EditProduct()}
              style={{ backgroundColor: "#252B6194" }}
            >
              ADD
            </Button>
          </HStack>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddProduct;
