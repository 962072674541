import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Card,
  CardBody,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  getOrdersAccordingToStatus,
  getOrdersAccordingToMedId,
  editOrderStatus,
} from "../../apis/orderApis";

const Orders = () => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [orderStatus, setorderStatus] = useState("");
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [select, setSelect] = useState();
  const [formData, setFormData] = useState({
    _id: "",
    deliveryDate: "",
    deliveryNote: "",
    status: "",
  });

  const onCloseModal1 = () => setIsOpenModal1(false);

  const onOpenModal1 = () => setIsOpenModal1(true);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length > 0) {
      console.log(getOrdersAccordingToMedId)
      const req = await getOrdersAccordingToMedId(value);
      if (req) {
        setDate(req);
      }
    }
  };

  const [data, setDate] = useState([]);
  async function fetchData() {
    setLoading(true);
    const req = await getOrdersAccordingToStatus(orderStatus);

    if (req) {
      setDate(req);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchData();
  }, [orderStatus]);

  const bgCheck = (status) => {
    if (status === "Live") {
      return "#EB8814";
    } else if (status === "Production") {
      return "#E3D069";
    } else if (status === "Delivered") {
      return "#2E8B57aa";
    } else {
      return "#FFCCCB";
    }
  };

  const handleStatusChange = async (item, newStatus) => {
    console.log(newStatus);
    setLoading(true);
    const data = {
      _id: item?._id,
      delivery_date: formData?.deliveryDate,
      delivery_note: formData?.deliveryNote,
      status: newStatus,
    };
    const req = await editOrderStatus(data);
    if (req) {
      fetchData();
    } else {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      _id: select?._id,
      delivery_date: formData?.deliveryDate,
      delivery_note: formData?.deliveryNote,
      status: formData?.status,
    };
    console.log("Form Data:", data);
    const req = await editOrderStatus(data);
    if (req) {
      fetchData();
      onCloseModal1();
      setFormData({
        _id: "",
        deliveryDate: "",
        deliveryNote: "",
        status: "",
      });
    } else {
      setLoading(false);
    }
  };
  return (
    <div className="Orders">
      <Card>
        <CardBody>
          <HStack
            style={{
              width: "90vw",
              justifyContent: "space-between",
            }}
          >
            <Input
              placeholder="Medicine Id"
              onChange={handleInputChange}
              value={inputValue}
              style={{ color: "black", width: "30%" }}
              _placeholder={{ color: "black" }}
            />
            <RadioGroup onChange={setorderStatus} value={orderStatus}>
              <HStack direction="row" gap="1.5rem">
                <Radio value="">All</Radio>
                <Radio value="Live">Live</Radio>
                <Radio value="Production">Production</Radio>
                <Radio value="Delivered">Delivered</Radio>
                <Radio value="Cancelled">Cancelled</Radio>
              </HStack>
            </RadioGroup>
          </HStack>

          {loading ? (
            <Stack>
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
            </Stack>
          ) : (
            <TableContainer mt={4}>
              <Table size="lg">
                <Thead backgroundColor={"#252B6194"} color={"black"}>
                  <Tr borderWidth="1px">
                    <Th>#</Th>
                    <Th>Client Name</Th>
                    <Th>OrderId</Th>
                    <Th>Order Date</Th>
                    <Th>Delivery Date</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody borderWidth="1px">
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <Tr
                        key={index}
                        _hover={{ background: "#f5f5f5", cursor: "pointer" }}
                      >
                        <Td>{index + 1}</Td>

                        <Td>{item?.userId?.name}</Td>

                        <Td>{item?._id}</Td>
                        <Td> {item?.orderDate}</Td>
                        <Td>
                          {item?.delivery_date === "40 days from order date"
                            ? "-"
                            : item?.delivery_date}
                        </Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              style={{ dispaly: "flex", width: 150 }}
                              backgroundColor={bgCheck(item?.status)}
                              as={Button}
                              rightIcon={<ChevronDownIcon />}
                              isDisabled={
                                item?.status === "Delivered" ||
                                item?.status === "Cancelled"
                              }
                            >
                              {item.status}
                            </MenuButton>
                            {item.status === "Live" ? (
                              <MenuList>
                                <MenuItem
                                  onClick={() =>
                                    handleStatusChange(item, "Production")
                                  }
                                >
                                  Production
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    //  handleStatusChange(item, "Delivered");
                                    setSelect(item);
                                    setFormData({
                                      ...formData,
                                      status: "Delivered",
                                    });
                                    onOpenModal1();
                                  }}
                                >
                                  Delivered
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    handleStatusChange(item, "Cancelled")
                                  }
                                >
                                  Cancel
                                </MenuItem>
                              </MenuList>
                            ) : item.status === "Production" ? (
                              <MenuList>
                                <MenuItem
                                  onClick={() => {
                                    //  handleStatusChange(item, "Delivered");
                                    setSelect(item);
                                    setFormData({
                                      ...formData,
                                      status: "Delivered",
                                    });
                                    onOpenModal1();
                                  }}
                                >
                                  Delivered
                                </MenuItem>
                              </MenuList>
                            ) : null}
                          </Menu>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={8}>No Such Orders Found</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
      <Modal
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        isCentered
        size={"sm"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader fontSize="1rem">Enter Date of Product Delivery</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <form className="resetForm" onSubmit={handleSubmit}>
                <div className="resertdiv">
                  <label>Delivery Date</label>
                  <Input
                    className="resetInput"
                    type="datetime-local"
                    name="deliveryDate"
                    required
                    placeholder="Date"
                    value={formData.deliveryDate}
                    onChange={handleChange}
                  />
                </div>
                <div className="resertdiv">
                  <label>Delivery Note</label>
                  <Textarea
                    className="resetInput"
                    type="text"
                    name="deliveryNote"
                    required
                    placeholder="Note"
                    value={formData.deliveryNote}
                    onChange={handleChange}
                  />
                </div>

                <button className="resetButton" type="submit">
                  Update
                </button>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </div>
  );
};

export default Orders;
