import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  addPacking,
  editPacking,
  getAllPackingType,
} from "../../apis/productApis";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FaPlus } from "react-icons/fa";

const PackingType = () => {
  const [packing, setPacking] = useState([]);

  const [loading, setloading] = useState(false);
  const [isaddModal, setIsaddModal] = useState(false);
  const [errText, seterrText] = useState(false);
  const onCloseAddModal = () => setIsaddModal(false);

  const onaddModal = () => setIsaddModal(true);

  const [isEditModal, setIsEditModal] = useState(false);
  const onCloseEditModal = () => setIsEditModal(false);

  const onEditModal = () => setIsEditModal(true);
  async function fetchData() {
    setloading(true);
    const packing = await getAllPackingType();

    if (packing) {
      setPacking(packing);
      setloading(false);
    }
    setloading(false);
  }
  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    type: "",
    value: "",
    active: true,
  });

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditSubmit = async (e, active) => {
    e.preventDefault();
    // console.log(formData);
    const data = {
      id: formData._id,
      type: formData.type,
      value: formData.value,
      active: active ? active : formData.active,
    };
    setloading(true);
    const req = await editPacking(data);
    // console.log(req);
    if (req.message === "Packing type status updated successfully") {
      onCloseEditModal();
      fetchData();
    } else {
      seterrText(req.message);
      setloading(false);
    }
  };

  const handleMenuItemClick = async (item, activeValue) => {
    const data = {
      id: item._id,
      type: item.type,
      value: item.value,
      active: activeValue,
    };
    setloading(true);
    const req = await editPacking(data);
    // console.log(req);
    if (req.message === "Packing type status updated successfully") {
      onCloseEditModal();
      fetchData();
    } else {
      seterrText(req.message);
      setloading(false);
    }
  };

  const [packingTypes, setPackingTypes] = useState([
    { type: "", value: "", active: true },
  ]);

  const handleChange = (index, field, value) => {
    const updatedPackingTypes = [...packingTypes];
    updatedPackingTypes[index][field] = value;
    setPackingTypes(updatedPackingTypes);
  };

  const handleSubmit = async (e) => {
    if (packingTypes.length === 0) {
      handleAddPackingType();
    } else {
      setloading(true);
      e.preventDefault();
      const data = {
        packingTypes: packingTypes,
      };
      try {
        const req = await addPacking(data);
        // console.log(req);
        if (req) {
          onCloseAddModal();
          fetchData();
          setPackingTypes([{ type: "", value: "", active: true }]);
        }
      } catch (error) {
        console.error("Error adding packing types:", error);
      }
    }
  };

  const handleAddPackingType = () => {
    setPackingTypes([...packingTypes, { type: "", value: "", active: true }]);
  };

  const handleRemovePackingType = (index) => {
    const updatedPackingTypes = [...packingTypes];
    updatedPackingTypes.splice(index, 1);
    setPackingTypes(updatedPackingTypes);
  };
  return (
    <div className="TabContainer">
      <Card>
        <CardBody>
          <HStack
            style={{
              display: "flex",
              justifyContent:"space-between",
              alignItems:"center",
              marginBottom:"1rem"
            }}
          >
            <Heading>Packing Types</Heading>
            <Button className="button" onClick={onaddModal}>
              + ADD
            </Button>
          </HStack>
          {loading ? (
            <Stack>
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
            </Stack>
          ) : (
            <VStack>
              {packing?.length > 0 &&
                packing.map((item) => (
                  <HStack
                    key={item?._id}
                    className="list-Item"
                    onClick={() => {
                      setFormData(item);
                    }}
                  >
                    <Stack flexDirection={"row"}>
                      <MdEdit
                        size={"20px"}
                        style={{ marginRight: "10px" }}
                        color={"#2d2d2d"}
                        onClick={() => {
                          setFormData(item);
                          onEditModal(true);
                        }}
                      />
                      <p>
                        {item?.type} = {item?.value}
                      </p>
                    </Stack>
                    <Menu>
                      <MenuButton
                        backgroundColor={item.active ? "#2E8B57aa" : "#FFCCCB"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        style={{ width: 120 }}
                      >
                        {item.active ? "Active" : "Inactive"}
                      </MenuButton>
                      <MenuList>
                        {!item.active ? (
                          <MenuItem
                            onClick={() => handleMenuItemClick(item, true)}
                          >
                            Activate
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => handleMenuItemClick(item, false)}
                          >
                            Deactivate
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </HStack>
                ))}
            </VStack>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isaddModal}
        onClose={onCloseAddModal}
        isCentered
        size={"lg"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Add Packing Type</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <form className="categoryForm" onSubmit={handleSubmit}>
                {/* <HStack>
                  <div className="categorydiv">
                    <label className="categoryLable">Packing Type</label>
                    <Input
                      className="categoryInput"
                      type="text"
                      required
                      name="type"
                      placeholder="Packing Type"
                      value={formData.type}
                      onChange={handleChange}
                    />
                  </div>
                  <p>=</p>
                  <div className="categorydiv">
                    <label className="categoryLable">Packing Value</label>
                    <Input
                      className="categoryInput"
                      type="number"
                      required
                      name="value"
                      placeholder="Packing value"
                      value={formData.value}
                      onChange={handleChange}
                    />
                  </div>
                </HStack> */}
                {packingTypes.map((packingType, index) => (
                  <HStack key={index} style={{ marginTop: 10 }}>
                    <FaPlus onClick={handleAddPackingType} />
                    <Input
                      type="text"
                      value={packingType.type}
                      onChange={(e) =>
                        handleChange(index, "type", e.target.value)
                      }
                      placeholder="Type"
                    />
                    <Input
                      type="text"
                      value={packingType.value}
                      onChange={(e) =>
                        handleChange(index, "value", e.target.value)
                      }
                      placeholder="Value"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemovePackingType(index)}
                    >
                      <RxCross2 />
                    </button>
                  </HStack>
                ))}
                {errText.length > 0 && <Text color={"red"}>{errText}</Text>}
                <button className="categoryButton" type="submit">
                  Add
                </button>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal
        isOpen={isEditModal}
        onClose={onCloseEditModal}
        isCentered
        size={"lg"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Edit Packing Type</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <form className="categoryForm" onSubmit={handleEditSubmit}>
                <HStack>
                  <div className="categorydiv">
                    <label className="categoryLable">Packing Type</label>
                    <Input
                      className="categoryInput"
                      type="text"
                      required
                      name="type"
                      placeholder="Packing Type"
                      value={formData.type}
                      onChange={handleEditChange}
                    />
                  </div>
                  <p>=</p>
                  <div className="categorydiv">
                    <label className="categoryLable">Packing Value</label>
                    <Input
                      className="categoryInput"
                      type="number"
                      required
                      name="value"
                      placeholder="Packing value"
                      value={formData.value}
                      onChange={handleEditChange}
                    />
                  </div>
                </HStack>
                {errText.length > 0 && <Text color={"red"}>{errText}</Text>}
                <button className="categoryButton" type="submit">
                  Edit
                </button>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </div>
  );
};

export default PackingType;
