import React, { useState } from "react";
import vg from "../../assets/crop.png";
import { PinInput, PinInputField, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useAppData } from "../../hooks/AppDataContext";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { BusinessRegister, VrifyOTPAPI } from "../../apis/authApis";
const SignUp = () => {
  const navigation = useNavigate();
  const { updateAppData } = useAppData();
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessNumber, setBusinessNumber] = useState("");
  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [pin, setPin] = useState();
  const [userType, setUserType] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [errtext, setErrText] = useState("");

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);

      let cord = await getLatLng(results[0]);
      let add = {
        full_add: results[0]?.formatted_address,
        // lati : results[0]?.geometry?.location?.lat ,
        // long : results[0]?.geometry?.location?.lng,
        pin: getAddressComponent(results[0]?.address_components, "postal_code"),
        city: getAddressComponent(results[0]?.address_components, "locality"),
        lat: cord.lat,
        lng: cord.lng,
        state: getAddressComponent(
          results[0]?.address_components,
          "administrative_area_level_1"
        ),
      };

      console.log(add);
      setAddress(results[0]?.formatted_address);
      setPinCode(add.pin);
      setCity(add.city);
      setProvince(add.state);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };
  const getAddressComponent = (addressComponents, type) => {
    const component = addressComponents.find((component) =>
      component.types.includes(type)
    );
    console.log(component.long_name);
    return component ? component.long_name : "";
  };
  const validateMobileNumber = (mobileNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(mobileNumber);
  };
  const handleOtpChange = (value) => {
    setPin(value);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setErrText("");

    if (!validateMobileNumber(mobile)) {
      setErrText("Please enter a valid 10-digit mobile number.");
      return;
    }
    const data = {
      name: name,
      userType: userType,
      mobile: mobile,
      email: email,
      mci: businessNumber,
      gstIn: businessNumber,
      address: address,
      city: city,
      state: province,
      pinCode: pinCode,
    };

    let req = await BusinessRegister(data);
    // console.log("======> res for register patients", req);
    if (req?.user?._id) {
      setCurrentStep(3);
    } else if (req?.response.data.title === "Error") {
      setErrText("This Phone number is already used, please login");
    }
  };
  const handleVerifyRegistration = async (e) => {
    e.preventDefault();
    // console.log("Email:", name, surName, email, phone, pin, d_id);
    if (pin == null || pin === "") {
      setErrText(`Otp Pin is required`);
    } else {
      const data = {
        mobile: mobile,
        otp: pin,
      };
      let req = await VrifyOTPAPI(data);
      // console.log("======> res for register patients", req);
      if (req?.response?.data?.title === "Error") {
        setErrText("Invalid OTP. Please try again.");
      } else {
        updateAppData({ userType: "Business", user: req?.user });
        localStorage.setItem("userType", "Business");
        localStorage.setItem("user", JSON.stringify(req?.user));
        navigation("/home");
      }
      // if (req) {
      //   updateAppData({ userType: "Business", user: req?.user });
      //   localStorage.setItem("userType", "Business");

      //   localStorage.setItem("user", JSON.stringify(req?.user));
      //   navigation("/home");
      // } else if (req?.mobileno) {
      //   setErrText("Error in registration");
      // }
    }
  };

  return (
    <div className="SignUpContainer">
      <main>
        <h1>Welcome to asdff Order Booking</h1>
        <h4>Sign up to continue</h4>
        {currentStep === 1 && (
          <form>
            <button
              onClick={() => {
                updateAppData({ userType: "Business" });
                localStorage.setItem("userType", "Business");
                setUserType("Doctor");
                setCurrentStep(2);
              }}
            >
              Medical Practitioner
            </button>
            <button
              onClick={() => {
                updateAppData({ userType: "Business" });
                localStorage.setItem("userType", "Business");
                setUserType("Business");
                setCurrentStep(2);
              }}
            >
              Business User
            </button>
            <Link to="/login" style={{marginTop:"15px"}}>
              Already have an account? <span>Login</span>
            </Link>
          </form>
        )}
        {currentStep === 2 && (
          <form onSubmit={handleRegistration}>
            <div>
              <div>
                <label>Business Name *</label>
                <input
                  type="text"
                  required
                  placeholder="Name of the business"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div>
                <label>Mobile Number *</label>
                <input
                  type="tel"
                  required
                  placeholder="+91 "
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                />
              </div>
            </div>
            <div>
              <div>
                <label>E mail ID *</label>
                <input
                  type="email"
                  required
                  placeholder="abc@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div>
                <label>
                  {userType === "Doctor" ? "MCI Number *" : "GST Number *"}
                </label>
                <input
                  type="text"
                  required
                  placeholder={
                    userType === "Doctor" ? "MCI Number" : "GST Number"
                  }
                  onChange={(e) => setBusinessNumber(e.target.value)}
                  value={businessNumber}
                />
              </div>
            </div>
            <div>
              <div>
                <label>Address *</label>
                <PlacesAutocomplete
                  value={address}
                  onChange={(value) => setAddress(value)}
                  onSelect={handleSelect}
                  className="form-control"
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <input
                        {...getInputProps({
                          placeholder: "Enter your address",
                          // className: "location-search-input",
                        })}
                        style={{
                          width: "100%",
                          alignSelf: "stretch",
                          padding: "1rem",
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: "#ccc",
                          outline: "none",
                        }}
                        // className="form-control"
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {/* <input
                  type="text"
                  required
                  //   placeholder="+91 "
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  style={{ display: "flex", width: "100%" }}
                /> */}
              </div>
            </div>
            <div>
              <div>
                <label>Pin Code *</label>
                <input
                  type="tel"
                  required
                  placeholder=""
                  onChange={(e) => setPinCode(e.target.value)}
                  value={pinCode}
                />
              </div>
              <div>
                <label>City *</label>
                <input
                  type="text"
                  required
                  placeholder="Bengaluru"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </div>
              <div>
                <label>State *</label>
                <input
                  type="text"
                  required
                  placeholder=""
                  onChange={(e) => setProvince(e.target.value)}
                  value={province}
                />
              </div>
            </div>
            {errtext.length > 0 && (
              <Text style={{ color: "red" }}>{errtext}</Text>
            )}
            <button type="submit">Send OTP</button>
          </form>
        )}
        {currentStep === 3 && (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              margin: 10,
            }}
          >
            <form
              onSubmit={handleVerifyRegistration}
              style={{
                flexDirection: "column",
                marginTop: "3rem",
                alignItems: "center",
                display: "flex",
              }}
            >
              <label style={{ textAlign: "left" }}>Enter OTP</label>
              <div
                style={{
                  flexDirection: "row",

                  // backgroundColor: "pink",
                }}
              >
                <PinInput
                  otp
                  size="lg"
                  onChange={(value) => handleOtpChange(value)}
                >
                  <PinInputField m={1} />
                  <PinInputField m={1} />
                  <PinInputField m={1} />
                  <PinInputField m={1} />
                  <PinInputField m={1} />
                  <PinInputField m={1} />
                </PinInput>
              </div>
              {errtext.length > 0 && (
                <Text style={{ color: "red" }}>{errtext}</Text>
              )}

              <button
                type="submit"
                style={{
                  margin: "1rem",
                  padding: "0.7rem",
                  cursor: "pointer",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#25307F",
                  color: "white", // Assuming 'first' is a variable holding a color value
                  width: "40%",
                  // font: "500 1rem text1",
                }}
              >
                Sign Up
              </button>
            </form>
          </div>
        )}
      </main>
      <div>
        <img src={vg} alt="Graphics" />
      </div>
    </div>
  );
};

export default SignUp;
