import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  HStack,
  Input,
  Select,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  VStack,
  Heading,
  CloseButton,
  Stack,
  Skeleton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import {
  editproducts,
  findProduct,
  getAllCategory,
  getAllPackingType,
  getAllPreparation,
  getAllProducts,
  getAllgst,
  getProductsbasedonCategory,
} from "../../apis/productApis";
import { MdEdit } from "react-icons/md";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [packing, setPacking] = useState([]);
  const [prepType, setPrepType] = useState([]);
  const [gstType, setGstType] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [inputValue, setInputValue] = useState("");
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    medId: "",
    name: "",
    composition: "",
    product_category: "",
    sub_category: "",
    packing_type: "",
    num_pack_value: "",
    preparation_type: "",
    shipper_pack_qty: "",
    batch_qty: "",
    batch_price: "",
    Moq: "",
    per1xMoq: "",
    per2xMoq: "",
    per3xMoq: "",
    per4xMoq: "",
    per5xMoq: "",
    hsn_code: "",
    gst_percentage: "",
    mrp: "",
    active: "",
  });

  async function fetchData() {
    setLoading(true);
    try {
      const req = await getAllProducts();
      const cat = await getAllCategory();
      const packing = await getAllPackingType();
      const preprationType = await getAllPreparation();
      const gsties = await getAllgst();

      if (req) {
        setProducts(req);
        setLoading(false);
      }
      if (cat) {
        setCategory(cat);
      }
      if (packing) {
        setPacking(packing);
      }
      if (preprationType) {
        setPrepType(preprationType);
      }
      if (gsties) {
        setGstType(gsties);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  const handleChange = async (event) => {
    const val = event.target.value;
    setSelectedCategory(val);
    const req = await getProductsbasedonCategory(val);
    if (req) {
      setProducts(req);
    }
  };

  const convertNameToCSV = () => {
    const csvData = [];
    // Add headers to CSV data
    csvData.push([
      "S No.",
      "name",
      "composition",
      "product_category",
      "sub_category",
      "packing_type",
      "num_pack_value",
      "preparation_type",
      "shipper_pack_qty",
      "batch_qty",
      "batch_price",
      "Moq",
      "per1xMoq",
      "per2xMoq",
      "per3xMoq",
      "per4xMoq",
      "per5xMoq",
      "hsn_code",
      "gst_percentage",
      "mrp",
    ]);

    // Add rows to CSV data
    products.length > 0 &&
      products.forEach((row, index) => {
        csvData.push([
          index + 1,
          row?.name,
          row?.composition,
          row?.product_category,
          row?.sub_category,
          row?.packing_type,
          row?.num_pack_value,
          row?.preparation_type,
          row?.shipper_pack_qty,
          row?.batch_qty,
          row?.batch_price,
          row?.Moq,
          row?.per1xMoq,
          row?.per2xMoq,
          row?.per3xMoq,
          row?.per4xMoq,
          row?.per5xMoq,
          row?.hsn_code,
          row?.gst_percentage,
          row?.mrp,
        ]);
      });

    return csvData;
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length > 3) {
      const req = await findProduct(value);
      if (req) {
        setProducts(req);
      }
    } else {
      fetchData();
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const EditProduct = async () => {
    // console.log("editing", formData);
    setLoading(true);
    const req = await editproducts(formData);
    if (req) {
      console.log("edit ", req);
      fetchData();
    }
    setModal(false);
  };

  const handleMenuItemClick = async (item, activeValue) => {
    const data = {
      medId: item?._id,

      active: activeValue,
    };
    setLoading(true);
    const req = await editproducts(data);
    // console.log(req);
    if (req) {
      fetchData();
    } else {
      setLoading(false);
    }
  };
  const onCloseAddModal = () => {
    setModal(false);
  };
  return (
    <div className="Products">
      <Card>
        <CardBody>
          <HStack>
            <Input
              placeholder="Name / Composition"
              onChange={handleInputChange}
              value={inputValue}
              style={{ color: "black" }}
              _placeholder={{ color: "black" }}
            />
            <Select
              placeholder="Select category"
              value={selectedCategory}
              onChange={handleChange}
              style={{ color: "black" }}
              _placeholder={{ color: "black" }}
            >
              {category?.length > 0 ? (
                category?.map((item) => (
                  <option key={item?._id} value={item?.name}>
                    {item?.name}
                  </option>
                ))
              ) : (
                <option>No Category Found</option>
              )}
            </Select>
            <CSVLink
              data={convertNameToCSV()}
              filename={"user_report.csv"}
              className="downloadButton1"
            >
              Export <SiMicrosoftexcel style={{ marginLeft: "15px" }} />
            </CSVLink>
          </HStack>
          <div className="buttondiv">
            <button
              className="buttonAdd"
              color="white"
              onClick={() => navigate("/add-product")}
            >
              + Add
            </button>
          </div>

          {loading ? (
            <Stack>
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
            </Stack>
          ) : (
            <TableContainer mt={4}>
              <Table size="md">
                <Thead backgroundColor={"#252B6194"} color={"black"}>
                  <Tr borderWidth="1px">
                    <Th className="column-10vw">#</Th>
                    <Th className="column-10vw">Name</Th>
                    <Th className="composition-column">Composition</Th>
                    <Th className="column-10vw">Batch Quantity</Th>
                    <Th className="column-10vw">Moq</Th>
                    <Th className="column-15vw">Category</Th>
                    <Th className="column-10vw">Status</Th>
                    <Th>Edit</Th>
                  </Tr>
                </Thead>

                <Tbody borderWidth="1px">
                  {products.length > 0 ? (
                    products.map((item, index) => (
                      <Tr
                        key={item?._id}
                        _hover={{ background: "#f5f5f5", cursor: "pointer" }}
                        onClick={() => {
                          setFormData(item);
                          // setModal(true);
                        }}
                      >
                        <Td className="column-10vw">{index + 1}</Td>

                        <Td className="column-10vw">{item?.name}</Td>
                        <Td className="composition-column">
                          <Text noOfLines={3}>{item?.composition?.name}</Text>
                        </Td>
                        <Td className="column-10vw">{item?.batch_qty}</Td>
                        <Td className="column-10vw"> {item?.Moq}</Td>
                        <Td className="column-15vw">
                          {item?.product_category?.name}
                        </Td>
                        <Td className="column-10vw">
                          <Menu>
                            <MenuButton
                              backgroundColor={
                                item.active ? "#2E8B57aa" : "#FFCCCB"
                              }
                              as={Button}
                              rightIcon={<ChevronDownIcon />}
                              style={{ width: 120 }}
                            >
                              {item.active ? "Active" : "Inactive"}
                            </MenuButton>
                            <MenuList>
                              {!item.active ? (
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(item, true)
                                  }
                                >
                                  Activate
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  onClick={() =>
                                    handleMenuItemClick(item, false)
                                  }
                                >
                                  Deactivate
                                </MenuItem>
                              )}
                            </MenuList>
                          </Menu>
                        </Td>
                        <Th>
                          <MdEdit
                            size={"20px"}
                            onClick={() => {
                              setFormData(item);
                              setModal(true);
                            }}
                          />
                        </Th>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={8}>No Such Products Found</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>

        <Modal isOpen={modal} onClose={onCloseAddModal} isCentered size={"4xl"}>
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>Edit Product</ModalHeader>
              <ModalCloseButton />

              <ModalBody>
                <Card className="popup-overlay">
                  <CardBody className="popup-content">
                    <HStack
                      style={{
                        justifyContent: "space-around",
                        padding: "1rem",
                      }}
                    >
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">Product Name</label>
                        <Input
                          name="name"
                          value={formData.name}
                          onChange={handleEditInputChange}
                          disabled={true}
                        />
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">Composition</label>
                        <Input
                          name="composition"
                          value={formData?.composition?.name}
                          onChange={handleEditInputChange}
                          disabled={true}
                        />
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">Category</label>
                        <Input
                          name="product_category"
                          value={formData?.product_category?.name}
                          onChange={handleEditInputChange}
                          disabled={true}
                        />
                      </VStack>
                    </HStack>
                    <HStack
                      style={{
                        justifyContent: "space-around",
                        padding: "1rem",
                      }}
                    >
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">Sub Category</label>
                        <Input
                          name="sub_category"
                          value={formData.sub_category?.name}
                          onChange={handleEditInputChange}
                          disabled={true}
                        />
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          Packing Type <span>*</span>
                        </label>
                        <Select
                          name="packing_type"
                          value={formData.packing_type}
                          onChange={handleEditInputChange}
                        >
                          {packing.length > 0 &&
                            packing.map((item) => (
                              <option value={item?.type}>{item?.type}</option>
                            ))}
                        </Select>
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          Packing Value <span>*</span>
                        </label>
                        <Input
                          name="num_pack_value"
                          value={formData.num_pack_value}
                          onChange={handleEditInputChange}
                        />
                      </VStack>
                    </HStack>
                    <HStack
                      style={{
                        justifyContent: "space-around",
                        padding: "1rem",
                      }}
                    >
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          Preparation Type <span>*</span>
                        </label>
                        <Select
                          name="preparation_type"
                          value={formData.preparation_type}
                          onChange={handleEditInputChange}
                        >
                          {prepType.length > 0 &&
                            prepType.map((item) => (
                              <option value={item?.name}>{item?.name}</option>
                            ))}
                        </Select>
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          Shipper Pack Quanity <span>*</span>
                        </label>
                        <Input
                          name="shipper_pack_qty"
                          value={formData.shipper_pack_qty}
                          onChange={handleEditInputChange}
                        />
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          Batch Quantity <span>*</span>
                        </label>
                        <Input
                          name="batch_qty"
                          value={formData.batch_qty}
                          onChange={handleEditInputChange}
                        />
                      </VStack>
                    </HStack>
                    <HStack
                      style={{
                        justifyContent: "space-around",
                        padding: "1rem",
                      }}
                    >
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          Batch Prize <span>*</span>
                        </label>
                        <Input
                          name="batch_price"
                          value={formData.batch_price}
                          onChange={handleEditInputChange}
                        />
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          MOQ <span>*</span>
                        </label>
                        <Input
                          name="Moq"
                          value={formData.Moq}
                          onChange={handleEditInputChange}
                          disabled={true}
                        />
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          HSN Code <span>*</span>
                        </label>
                        <Input
                          name="hsn_code"
                          value={formData.hsn_code}
                          onChange={handleEditInputChange}
                        />
                      </VStack>
                    </HStack>
                    <HStack
                      style={{
                        justifyContent: "space-around",
                        padding: "1rem",
                      }}
                    >
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          GST % <span>*</span>
                        </label>
                        <Select
                          name="gst_percentage"
                          value={formData.gst_percentage}
                          onChange={handleEditInputChange}
                        >
                          {gstType.length > 0 &&
                            gstType.map((item) => (
                              <option value={item?.name}>{item?.name}</option>
                            ))}
                        </Select>
                      </VStack>
                      <VStack style={{ display: "flex", width: "30%" }}>
                        <label className="label">
                          MRP <span>*</span>
                        </label>
                        <Input
                          name="mrp"
                          value={formData.mrp}
                          onChange={handleEditInputChange}
                        />
                      </VStack>
                    </HStack>
                    <HStack
                      style={{
                        justifyContent: "space-around",
                        padding: "1rem",
                      }}
                    >
                      <Button
                        onClick={() => EditProduct()}
                        style={{ backgroundColor: "#252B6194" }}
                      >
                        Edit
                      </Button>
                    </HStack>
                  </CardBody>
                </Card>
              </ModalBody>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      </Card>
    </div>
  );
};

export default Products;
