import { Navigate } from "react-router-dom";
import { useAppData } from "./hooks/AppDataContext";

const ProtectedRoute = ({ element, redirectTo }) => {
  const { appData } = useAppData();
  const { isLoggedIn } = appData;

  return isLoggedIn ? <Navigate to={redirectTo} replace /> : element;
};

export default ProtectedRoute;
