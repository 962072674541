import React, { useState, createContext, useContext, useEffect } from "react";

// Create a Context for app data
const AppDataContext = createContext();

// Provider component for app data
export const AppDataProvider = ({ children }) => {
  const [appData, setAppData] = useState({
    user: null,
    isLoggedIn: false,
    userType: null,
  });

  // Fetch data from local storage and update state
  useEffect(() => {
    async function fetchData() {
      try {
        const storedUser = localStorage.getItem("user");
        const userType = localStorage.getItem("userType") || null;

        if (storedUser) {
          const parsedUser = JSON.parse(storedUser);
          setAppData({
            user: parsedUser,
            isLoggedIn: true,
            userType: userType,
          });
        }
      } catch (error) {
        console.error("Failed to fetch user data from localStorage", error);
      }
    }

    fetchData();
  }, []);

  // Update app data and synchronize with local storage
  const updateAppData = (newData) => {
    setAppData((prevData) => {
      const updatedData = { ...prevData, ...newData };

      if (updatedData.user) {
        localStorage.setItem("user", JSON.stringify(updatedData.user));
        localStorage.setItem("userType", updatedData.userType || "");
      } else {
        localStorage.removeItem("user");
        localStorage.removeItem("userType");
      }

      return updatedData;
    });
  };

  // Handle user logout
  const logout = () => {
    setAppData({
      user: null,
      isLoggedIn: false,
      userType: null,
    });
    localStorage.removeItem("user");
    localStorage.removeItem("userType");
    // Optionally redirect to login page or show a notification
    // history.push('/login'); // if using react-router
  };

    // Add a method to update products
    const setProducts = (products) => {
      setAppData((prevData) => ({
        ...prevData,
        products: products,
      }));
    };
  

  return (
    <AppDataContext.Provider value={{ appData, updateAppData, logout }}>
      {children}
    </AppDataContext.Provider>
  );
};

// Custom hook to use app data context
export const useAppData = () => {
  const context = useContext(AppDataContext);

  if (!context) {
    throw new Error("useAppData must be used within an AppDataProvider");
  }

  return context;
};
