import { apiClient } from "./configs/axiosConfig";

export const BusinessRegister = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/signup`, data)
    .then((res) => {
      // console.log("res===>patient/create", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const BusinessLogin = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/login`, data)
    .then((res) => {
      // console.log("res===>patient/create", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const VrifyOTPAPI = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/verify-otp`, data)
    .then((res) => {
      // console.log("res===>patient/Login", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Login ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
export const AdminLoginApi = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/admin/login`, data)
    .then((res) => {
      // console.log("res===>admin/Login", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Login ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const forgetPassword = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/forgot-password`, data)
    .then((res) => {
      // console.log("res===>admin/Login", res);
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Login ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
