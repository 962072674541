import React, { useEffect, useState } from "react";
import { getOrdersAccordingToUserId, postOrder } from "../../apis/orderApis";
import { formatDate } from "../../helpers/DateFormatter";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Input,
  Stack,
  Skeleton,
  useToast
} from "@chakra-ui/react";

const MyOrders = () => {
  const toast = useToast();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [reorderData, setReorderData] = useState([]);
  const [isReorderModalOpen, setIsReorderModalOpen] = useState(false);
  const [gstType, setgstType] = useState();
  const [totalQty, settotalQty] = useState(0);
  const [ttltaxAmt, setttltaxAmt] = useState(0);
  const [ttlgstAmt, setttlgstAmt] = useState(0);
  const [totalAmount, settotalAmount] = useState(0);

  async function fetchData() {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const req = await getOrdersAccordingToUserId(user?._id);
    if (req) {
      setData(req.reverse());
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.state === "Karnataka" || user.state === "karnataka") {
      setgstType("IGST");
    } else {
      setgstType("CGST");
    }
    fetchData();
  }, []);

  const openReorderModal = (index, item) => {
    setReorderData(data[index].products);
    setIsReorderModalOpen(true);
  };

  const handleQuantityChange = (index, value) => {
    const updatedReorderData = [...reorderData];
    const product = updatedReorderData[index];
    const oldQuantity = product.quantity;
    product.quantity = value;
    const gstAmount = (product.gst_percentage / 100) * product.amount;
    const taxableAmount = (product.amount - gstAmount) * (value / oldQuantity);
    const amt = taxableAmount + gstAmount;
    const pricePerUnit = amt / value;
    product.gst_amount = gstAmount;
    product.tax_amount = taxableAmount;
    product.amount = amt;
    product.per_unit = pricePerUnit;
    setReorderData(updatedReorderData);

    totalAmts();
  };

  const totalAmts = () => {
    let total = 0;
    let qty = 0;
    let taxableAmt = 0;
    let totalgst = 0;
    reorderData.forEach((product) => {
      if (product && product.amount) {
        total += parseFloat(product.amount);
        qty += parseFloat(product.quantity);
        taxableAmt += parseFloat(product.tax_amount);
        totalgst += parseFloat(product.gst_amount);
      }
    });
    settotalAmount(total);
    settotalQty(qty);
    setttltaxAmt(taxableAmt);
    setttlgstAmt(totalgst);
  };

  const handleReorder = async () => {
    // Handle reorder action here
    await totalAmts();
    const user = JSON.parse(localStorage.getItem("user"));
    const data = {
      userId: user?._id,
      products: reorderData,
      total_amount: totalAmount,
      gst_type: gstType,
      total_quantity: totalQty,
      total_tax_amt: ttltaxAmt,
      total_gst: ttlgstAmt,
    };
    console.log(data);
    setIsReorderModalOpen(false);
    const req = await postOrder(data);
    if(req.message === "Product order added successfully"){
      toast({
        title: req.message,
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-left",
      });

    }
    setLoading(true);
    if (req) {
      fetchData();
    } else {
      setLoading(false);
    }
    // console.log(req);
  };

  return (
    <div className="myorders">
      {loading ? (
        <Stack>
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
          <Skeleton height="50px" />
        </Stack>
      ) : (
        <TableContainer style={{ width: "100%" }}>
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Order Details</Th>
                <Th>Status</Th>
                <Th>ReOrder</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.length > 0
                ? data.map((item, index) => (
                    <Tr key={index} _hover={{ cursor: "pointer" }}>
                      <Td>{formatDate(item.orderDate)}</Td>
                      <Td>
                        {item.products.map((order) => (
                          <p>{order?.name},</p>
                        ))}
                      </Td>
                      <Td>{item.status}</Td>
                      <Td className="orderTd">
                        <button onClick={() => openReorderModal(index, item)}>
                          Re Order
                        </button>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Modal
        isOpen={isReorderModalOpen}
        onClose={() => setIsReorderModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reorder Items</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table>
              <Thead>
                <Tr>
                  <Th>Product</Th>
                  <Th>Quantity</Th>
                </Tr>
              </Thead>
              <Tbody>
                {reorderData.map((product, index) => (
                  <Tr key={index}>
                    <Td>{product.name}</Td>
                    <Td>
                      <Input
                        type="number"
                        min={1}
                        value={product.quantity}
                        onChange={(e) =>
                          handleQuantityChange(index, e.target.value)
                        }
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Button mt={4} onClick={handleReorder} style={{backgroundColor:"#67214B" ,  color:"#FFF" }}>
              Confirm Reorder
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default MyOrders;
