import React, { useEffect, useState } from "react";
import vg from "../../assets/crop.png";
import { border, PinInput, PinInputField, Text } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BusinessLogin, VrifyOTPAPI } from "../../apis/authApis";
import { useAppData } from "../../hooks/AppDataContext";
const Login = () => {
  const navigate = useNavigate();
  const { updateAppData } = useAppData();
  const [mobile, setMobile] = useState("");
  const [pin, setPin] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [errtext, setErrText] = useState("");
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (location.pathname === "/login") {
      localStorage.setItem("userType", "Business");
    }
  }, []);

  const validateMobileNumber = (mobileNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(mobileNumber);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrText("");
    if (!validateMobileNumber(mobile)) {
      setErrText("Please enter a valid 10-digit mobile number.");
      return;
    }
    const data = {
      mobile,
    };
    let req = await BusinessLogin(data);
    console.log("======> res for register patients", req);
    if (req?.title) {
      setCurrentStep(2);
    } else if (req?.response.data.title === "Error") {
      setErrText("This Phone number is not register, please signup");
    }
  };

  const handleVerifyRegistration = async (e) => {
    e.preventDefault();
    // console.log("Email:", name, surName, email, phone, pin, d_id);
    if (pin == null || pin === "") {
      setErrText(`Otp Pin is required`);
    } else {
      const data = {
        mobile: mobile,
        otp: pin,
      };
      let req = await VrifyOTPAPI(data);
      // console.log("======> res for register patients", req);
      if (req?.response?.data?.title === "Error") {
        setErrText("Invalid OTP. Please try again.");
      } else {
        await updateAppData({ userType: "Business", user: req?.user });
        await localStorage.setItem("userType", "Business");
        await localStorage.setItem("user", JSON.stringify(req?.user));
        await navigate("/home");
      }
      // if (req) {
      //   updateAppData({ userType: "Business", user: req?.user });
      //   await localStorage.setItem("userType", "Business");

      //   await localStorage.setItem("user", JSON.stringify(req?.user));
      //   await navigation("/home");
      // } else if (req?.response.data.title === "Error") {
      //   setErrText("Error in Login");
      // }
    }
  };
  const handleOtpChange = (value) => {
    setPin(value);
  };

  return (
    <div className="loginBussinessContainer">
      <main>
        <h1>Welcome to Cermax Order Booking</h1>
        <h4>Sign in to continue</h4>

        {currentStep === 1 && (
          <form onSubmit={handleLogin}>
            <div>
              <label>Mobile Number</label>
              <div className="phoneNumberInput">
                <div>+91</div>
                <input
                  type="tel"
                  required
                  placeholder="Enter Mobile Number"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                />
              </div>
            </div>
            {errtext.length > 0 && (
              <Text style={{ color: "red" }}>{errtext}</Text>
            )}
            <button type="submit">Send OTP</button>
          </form>
        )}
        {currentStep === 2 && (
          <form onSubmit={handleVerifyRegistration}>
            <div>
              <label>Enter OTP</label>
              <div className="otpDiv">
                <PinInput  otp size="lg" value={pin} onChange={handleOtpChange}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </div>
            </div>
            {errtext.length > 0 && (
              <Text style={{ color: "red" }}>{errtext}</Text>
            )}

            <button type="submit">Login</button>
          </form>
        )}
        <div className="dontAccount">
          <Link to="/signup">
            Don't have an account? <span>SignUp</span>
          </Link>
        </div>
      </main>
      <div>
        <img src={vg} alt="Graphics" />
      </div>
    </div>
  );
};

export default Login;
