import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/cermax-Logo.png";
import { MdAccountCircle } from "react-icons/md";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useAppData } from "../../hooks/AppDataContext";

const Header = () => {
  const { logout } = useAppData();
  const navigate = useNavigate();
  const { appData } = useAppData();
  console.log(appData)
  const { userType } = appData;
  const location = useLocation();
  const { pathname } = location;

  const Logout = () => {
    logout();
    navigate("/login");
  };
  const AdminLogout = () => {
    logout();

    navigate("/admin/login");
  };

  const showContent =
    pathname !== "/" &&
    ["/home", "/myorders", "/contact"].some((path) => pathname.includes(path));
  return (
    <nav>
      <img
        src={Logo}
        alt="logo"
        onClick={() =>
          userType === "Admin" ? navigate("/dashboard") : navigate("/home")
        }
      />

      {userType === "Admin" ? (
        <main>
           <Link
            to="/dashboard"
            className={location.pathname === "/dashboard" ? "active" : ""}
          >
            Home
          </Link>
          <Link
            to="/products"
            className={location.pathname === "/products" ? "active" : ""}
          >
            Products
          </Link>
          <Link
            to="/orders"
            className={location.pathname === "/orders" ? "active" : ""}
          >
            Orders
          </Link>
          <Link
            to="/settings"
            className={location.pathname === "/settings" ? "active" : ""}
          >
            Settings
          </Link>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<MdAccountCircle size={30} />}
              variant="outline"
              style={{ backgroundColor: "white" }}
            />
            <MenuList>
              <MenuItem onClick={() => AdminLogout()}>Log Out</MenuItem>
            </MenuList>
          </Menu>
        </main>
      ) : (
        <main>
          {showContent ? (
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MdAccountCircle size={30} />}
                variant="outline"
                style={{ backgroundColor: "white" }}
              />

              <MenuList>
                {
                  location.pathname === '/myorders' ? (
                    <Link to={"/home"}>
                    <MenuItem>Home</MenuItem>
                  </Link>
                  ): location.pathname === '/home' ?(
                    <Link to={"/myorders"}>
                    <MenuItem>My orders</MenuItem>
                  </Link>
                  ):null
                }
              
             
                <MenuItem onClick={() => Logout()}>Log Out</MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <div className="cta-buttons">
              <Link href="#login" className="btn" to="/login">
                Login
              </Link>
              <Link href="#login" className="btn" to="/signup">
                Sign Up
              </Link>
            </div>
          )}
        </main>
      )}
    </nav>
  );
};

export default Header;
