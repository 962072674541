import { apiClient } from "./configs/axiosConfig";

export const findProducts = async (term) => {
  let responseToBeSent;
  await apiClient
    .get(`/medfind?searchterm=${term}`)
    .then((res) => {
      responseToBeSent = res?.data?.products;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getAllProducts = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/getmed`)
    .then((res) => {
      responseToBeSent = res?.data?.products;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getAllCategory = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/categories`)
    .then((res) => {
      responseToBeSent = res?.data?.categories;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const postCategory = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/categories`, data)
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
export const EditCategory = async (data) => {
  let responseToBeSent;
  await apiClient
    .put(`/categories`, data)
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};

export const SubCatStatus = async (data) => {
  let responseToBeSent;

  await apiClient
    .put(`/subcategories`, data)
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};

// --------------------------------------------
export const getAllComposition = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/compositions`)
    .then((res) => {
      responseToBeSent = res?.data?.compositions;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const postComposition = async (data) => {
  console.log(data);
  let responseToBeSent;
  await apiClient
    .post(`/compositions`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const EditComposition = async (data) => {
  // console.log(data);
  let responseToBeSent;
  await apiClient
    .patch(`/compositions/${data._id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};
// -------------------------------------------
export const getProductsbasedonCategory = async (data) => {
  let responseToBeSent;
  await apiClient
    .get(`/products/${data}`)
    .then((res) => {
      responseToBeSent = res?.data?.products;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};

export const findProduct = async (data) => {
  let responseToBeSent;
  await apiClient
    .get(`/medfind?searchterm=${data}`)
    .then((res) => {
      responseToBeSent = res?.data?.products;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const getAllPackingType = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/packing-types`)
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const addPacking = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/packing-types`, data)
    .then((res) => {
      responseToBeSent = res.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};
export const editPacking = async (data) => {
  let responseToBeSent;
  await apiClient
    .put(`/packing-types`, data)
    .then((res) => {
      responseToBeSent = res.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};
export const getAllPreparation = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/preparation-types`)
    .then((res) => {
      responseToBeSent = res?.data?.preparationTypes;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
export const addPreparation = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/preparation-types`, data)
    .then((res) => {
      responseToBeSent = res.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};
export const editPreparation = async (data) => {
  let responseToBeSent;
  await apiClient
    .put(`/preparation-types`, data)
    .then((res) => {
      responseToBeSent = res.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = err.response.data;
    });
  return responseToBeSent;
};
export const getAllgst = async () => {
  let responseToBeSent;
  await apiClient
    .get(`/gstPercentage`)
    .then((res) => {
      responseToBeSent = res?.data?.gstTypes;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const editgstactive = async (data) => {
  let responseToBeSent;
  await apiClient
    .put(`/gstPercentage`, data)
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const addgst = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/gstPercentage`, data)
    .then((res) => {
      responseToBeSent = res?.data?.message;
    })
    .catch((err) => {
      console.log("Error in Pat Register ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const editproducts = async (data) => {
  console.log(data);
  let responseToBeSent;
  await apiClient
    .put(`/editMedicine`, data)
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Editing Product ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};

export const addproducts = async (data) => {
  let responseToBeSent;
  await apiClient
    .post(`/addSingleMedicine`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      responseToBeSent = res?.data;
    })
    .catch((err) => {
      console.log("Error in Editing Product ====>17", err);
      responseToBeSent = { ...err };
    });
  return responseToBeSent;
};
