import React, { useEffect, useState } from "react";
import SideDrawer from "./SideDrawer";
import {
  Card,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Button,
  Input,
  InputRightElement,
  IconButton,
  InputGroup,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import { blockUser, findUser, get_all_users } from "../../apis/UserApis";
import { CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const UserReport = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [data, setDate] = useState([]);
  const [searchTerm, setSearch] = useState("");

  async function fetchData() {
    setloading(true);
    const req = await get_all_users();

    if (req) {
      setDate(req);
      setloading(false);
    } else {
      setloading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const BlockUser = async (item) => {
    const user = JSON.parse(localStorage.getItem("user"));
    setloading(true);
    const data = {
      userId: user._id,
      userType: user?.userType,
      blockId: item?._id,
    };
    const req = await blockUser(data);
    if (req) {
      fetchData();
    } else {
      setloading(false);
    }
  };

  const downloadUserCsv = () => {
    const csvData = [];
    csvData.push([
      "Sr No",
      "Client Name",
      "Usertype",
      "Mobile",
      "Email",
      "Blocked",
    ]);
    data.length > 0 &&
      data?.forEach((row, index) => {
        csvData.push([
          index + 1,
          row.name,
          row.userType,
          row.mobile,
          row.email,
          row.blocked,
        ]);
      });
    return csvData;
  };

  const handleChange = async (e) => {
    // console.log(e);

    const searchTerm = e.target.value;
    setSearch(searchTerm);
    if (searchTerm.length >= 3) {
      const req = await findUser(searchTerm);
      setDate(req);
    }
  };

  const goToScreen2 = (data) => {
    navigate("/user", { state: { data: data } });
  };

  return (
    <div className="PendingReport">
      <SideDrawer />
      <Card w="100%">
        <CardBody>
          <Heading as="h2" size="lg" mb={4}>
            User Report
          </Heading>

          <TableContainer>
            <CSVLink
              data={downloadUserCsv()}
              filename={"User_Report.csv"}
              className="downloadButton"
            >
              Export <SiMicrosoftexcel style={{ marginLeft: "15px" }} />
            </CSVLink>
            <InputGroup>
              <Input
                className="searchbar"
                placeholder="Name or Mobile Number"
                value={searchTerm}
                onChange={handleChange}
              />
              {searchTerm && (
                <InputRightElement>
                  <IconButton
                    aria-label="Clear search"
                    icon={<CloseIcon />}
                    onClick={() => {
                      setSearch("");
                      fetchData();
                    }}
                    variant="ghost"
                  />
                </InputRightElement>
              )}
            </InputGroup>
            {loading ? (
              <Stack>
                <Skeleton height="50px" />
                <Skeleton height="50px" />
                <Skeleton height="50px" />
                <Skeleton height="50px" />
                <Skeleton height="50px" />
              </Stack>
            ) : (
              <Table size="lg" borderWidth="1px" border="1px solid">
                <Thead backgroundColor={"#252B6194"} color={"black"}>
                  <Tr borderWidth="1px">
                    <Th width="15px">Sr No</Th>
                    <Th>Client Name</Th>
                    <Th>Type</Th>
                    <Th>Contact</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody borderWidth="1px">
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <Tr
                        key={index}
                        _hover={{ background: "#f5f5f5", cursor: "pointer" }}
                        // onClick={() => goToScreen2(item?._id)}
                      >
                        <Td>{index + 1}</Td>
                        <Td onClick={() => goToScreen2(item?._id)}>
                          {item?.name}
                        </Td>
                        <Td>{item?.userType}</Td>
                        <Td>
                          {item.email} | {item?.mobile}
                        </Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={Button}
                              rightIcon={<FaCaretDown />}
                              style={{ width: 120 }}
                              backgroundColor={
                                !item.blocked ? "#2E8B57aa" : "#FFCCCB"
                              }
                            >
                              {item.blocked ? "Blocked" : "Active"}
                            </MenuButton>
                            <MenuList>
                              {!item.blocked ? (
                                <MenuItem onClick={() => BlockUser(item)}>
                                  Block
                                </MenuItem>
                              ) : (
                                <MenuItem onClick={() => BlockUser(item)}>
                                  Un Block
                                </MenuItem>
                              )}
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td>No User Found</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            )}
          </TableContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default UserReport;
