import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Category from "./Categories";
import PackingType from "./PackingType";
import PreparationType from "./PreparationType";
import GstType from "./GST";
import Composition from "./Composition";

const Settings = () => {
  return (
    <div className="settings">
      <Tabs variant="unstyled" style={{ display: "flex" }}>
        <div style={{background:"green ; "}}>
        <TabList
          display="flex"
          flexDirection="column"
          width="300px" // Adjust as needed
          border="1px solid"
          borderColor="gray.300"
          gap="1.5rem"
          height="fit-content"
          padding="2rem 0"
          marginTop="2rem"
          position="sticky"
          top="8.2rem"
        >
          <Tab _selected={{ color: "white", bg: "#67214B" }}>Category</Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>Composition</Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>Packing Type</Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>
            Preparation Type
          </Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>GST</Tab>
        </TabList>
        </div>

        <TabPanels
          display="flex"
          flex="1"
          marginLeft="16px" // Adjust as needed to create space
        >
          <TabPanel>
            <Category />
          </TabPanel>
          <TabPanel>
            <Composition />
          </TabPanel>
          <TabPanel>
            <PackingType />
          </TabPanel>
          <TabPanel>
            <PreparationType />
          </TabPanel>
          <TabPanel>
            <GstType />
          </TabPanel>
        </TabPanels>
      </Tabs>
      {/* <Tabs
        // variant="soft-rounded"
        // variant="unstyled"
        // style={{ display: "flex" , justifyContent:"flex-start" , alignItems:"flex-start"}}
      >
        <TabList
          // style={{ flexDirection: "column", width: "50vw" }}
          className="Tabs"
        >
          <Tab _selected={{ color: "white", bg: "#67214B" }}>Category</Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>Composition </Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>Packing Type</Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>
            Preparation Type
          </Tab>
          <Tab _selected={{ color: "white", bg: "#67214B" }}>GST </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Category />
          </TabPanel>
          <TabPanel>
            <Composition />
          </TabPanel>
          <TabPanel>
            <PackingType />
          </TabPanel>
          <TabPanel>
            <PreparationType />
          </TabPanel>
          <TabPanel>
            <GstType />
          </TabPanel>
        </TabPanels>
      </Tabs> */}
      {/* <div>
        <div style={{width:"400px" , height:"400px", backgroundColor:"green"}}>
          
        </div>
        <div></div>
      </div> */}
    </div>
  );
};

export default Settings;
