import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  VStack,
  Text,
  Stack,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  Heading,
} from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  EditComposition,
  getAllCategory,
  getAllComposition,
  postComposition,
} from "../../apis/productApis";

const Composition = () => {
  const [composition, setComposition] = useState([]);
  const [selectedComp, setSelectedComp] = useState(null);
  const [isOpenModal1, setIsOpenModal1] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
  });
  const [moq, setMoq] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const comp = await getAllComposition();
      console.log(comp);
      setComposition(comp || []);
    } catch (err) {
      setError(err.message || "Failed to fetch compositions");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const catResponse = await getAllCategory();
      setCategories(catResponse || []);
    } catch (err) {
      setError(err.message || "Failed to fetch categories");
    }
  };

  const fetchSubcategories = async (categoryId) => {
    try {
      const subcatResponse = await getAllCategory(categoryId);
      setSubcategories(subcatResponse || []);
    } catch (err) {
      setError(err.message || "Failed to fetch subcategories");
    }
  };

  const handleMenuItemClick = async (item, activeValue) => {
    const data = {
      _id: item?._id,
      active: activeValue,
    };
    setLoading(true);
    try {
      const req = await EditComposition(data);
      if (req.message === "Composition updated successfully") {
        fetchData();
      } else {
        setError(req.message);
      }
    } catch (err) {
      setError(err.message || "Failed to update status");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubcategories(selectedCategory);
    }
  }, [selectedCategory]);

  const onCloseModal1 = () => setIsOpenModal1(false);
  const onOpenModal1 = () => setIsOpenModal1(true);

  const onCloseAddModal = () => setIsAddModalOpen(false);
  const onAddModal = () => setIsAddModalOpen(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const req = await postComposition({
        ...formData,
        category: selectedCategory,
        subcategory: selectedSubcategory,
        moq: moq,
      });
      if (req.message === "Composition added successfully") {
        onCloseAddModal();
        fetchData();
        setFormData({ name: "" });
        setMoq("");
        setSelectedCategory("");
        setSelectedSubcategory("");
      } else {
        setError(req.message);
      }
    } catch (err) {
      setError(err.message || "Failed to add composition");
    } finally {
      setLoading(false);
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const req = await EditComposition({
        _id: selectedComp._id,
        ...formData,
        category: selectedCategory,
        subcategory: selectedSubcategory,
        moq: moq,
      });
      if (req.message === "Composition updated successfully") {
        onCloseModal1();
        fetchData();
        setSelectedComp(null);
        setFormData({ name: "" });
        setMoq("");
        setSelectedCategory("");
        setSelectedSubcategory("");
      } else {
        setError(req.message);
      }
    } catch (err) {
      setError(err.message || "Failed to update composition");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="TabContainer">
      <Card>
        <CardBody>
          <HStack 
            style={{
              display: "flex",
              justifyContent:"space-between",
              alignItems:"center",
              marginBottom:"1rem"
            }}>
          <Heading>Composition</Heading>
            <Button className="button" onClick={onAddModal}>
              + ADD
            </Button>
          </HStack>
          {loading ? (
            <Stack spacing={2}>
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
              <Skeleton height="50px" />
            </Stack>
          ) : (
            <Accordion>
              {composition.length > 0 &&
                composition.map((item) => (
                  <AccordionItem key={item?._id}>
                    <h2>
                      <AccordionButton>
                        <MdEdit
                          size={"20px"}
                          style={{ marginRight: "10px" }}
                          color={"#2d2d2d"}
                          onClick={() => {
                            setSelectedComp(item);
                            setFormData({ name: item.name });
                            setSelectedCategory(item.category_id || "");
                            setSelectedSubcategory(item.subcategory_id || "");
                            setMoq(item.moq || "");
                            onOpenModal1();
                          }}
                        />
                        <Box as="span" flex="1" textAlign="left">
                          {item?.name}
                        </Box>
                        <Menu>
                          <MenuButton
                            backgroundColor={
                              item.active ? "#2E8B57aa" : "#FFCCCB"
                            }
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            style={{ width: 120 }}
                          >
                            {item.active ? "Active" : "Inactive"}
                          </MenuButton>
                          <MenuList>
                            {!item.active ? (
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick(item, "true")
                                }
                              >
                                Activate
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={() =>
                                  handleMenuItemClick(item, "false")
                                }
                              >
                                Deactivate
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      </AccordionButton>
                    </h2>
                  </AccordionItem>
                ))}
            </Accordion>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Composition</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleEditSubmit}>
              <VStack spacing={4}>
                <Select
                  placeholder="Select Category"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  required
                >
                  {categories.length > 0 &&
                    categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                </Select>
                <Select
                  placeholder="Select Subcategory"
                  value={selectedSubcategory}
                  onChange={(e) => setSelectedSubcategory(e.target.value)}
                  required
                >
                  {selectedCategory &&
                    categories
                      .find((category) => category._id === selectedCategory)
                      ?.subcategories?.map((subcategory) => (
                        <option key={subcategory._id} value={subcategory._id}>
                          {subcategory.name}
                        </option>
                      ))}
                </Select>
                <Input
                  type="text"
                  name="name"
                  placeholder="Composition Name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required
                />
                <Input
                  type="number"
                  name="moq"
                  placeholder="MOQ"
                  value={moq}
                  onChange={(e) => setMoq(e.target.value)}
                  required
                />
                {error && <Text color="red">{error}</Text>}
                <Button type="submit">Update Composition</Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isAddModalOpen}
        onClose={onCloseAddModal}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Composition</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4}>
                <Select
                  placeholder="Select Category"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  required
                >
                  {categories.length > 0 &&
                    categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                </Select>
                <Select
                  placeholder="Select Subcategory"
                  value={selectedSubcategory}
                  onChange={(e) => setSelectedSubcategory(e.target.value)}
                  required
                >
                  {selectedCategory &&
                    categories
                      .find((category) => category._id === selectedCategory)
                      ?.subcategories?.map((subcategory) => (
                        <option key={subcategory._id} value={subcategory._id}>
                          {subcategory.name}
                        </option>
                      ))}
                </Select>
                <Input
                  type="text"
                  name="name"
                  placeholder="Composition Name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  required
                />
                <Input
                  type="number"
                  name="moq"
                  placeholder="MOQ"
                  value={moq}
                  onChange={(e) => setMoq(e.target.value)}
                  required
                />
                {error && <Text color="red">{error}</Text>}
                <Button type="submit">Add Composition</Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Composition;
