import React, { useEffect, useState } from "react";
import SideDrawer from "./SideDrawer";
import {
  getPendingOrderscomp,
  getPendingOrderscompoandTotal,
} from "../../apis/orderApis";
import {
  Card,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";

const PendingReport = () => {
  const [data, setDate] = useState([]);
  const [compData, setCompDate] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const req = await getPendingOrderscompoandTotal();
      const compreq = await getPendingOrderscomp();

      if (req) {
        setDate(req);
      }
      if (compreq) {
        setCompDate(compreq);
      }
    }
    fetchData();
  }, []);

  const convertNameToCSV = () => {
    const csvData = [];
    // Add headers to CSV data
    csvData.push(["Sr No", "Name", "Composition", "Quantity"]);

    // Add rows to CSV data
    data.length > 0 &&
      data.forEach((row, index) => {
        csvData.push([
          index + 1,
          row._id?.name,
          row._id?.composition,
          row.totalQuantity,
        ]);
      });

    return csvData;
  };
  const convertCompToCSV = () => {
    const csvData = [];
    // Add headers to CSV data
    csvData.push(["Sr No", "Composition", "Quantity"]);

    // Add rows to CSV data
    compData.length > 0 &&
      compData.forEach((row, index) => {
        csvData.push([index + 1, row._id, row.totalQuantity]);
      });

    return csvData;
  };

  return (
    <div className="PendingReport">
      <SideDrawer />
      <Card w="100%">
        <CardBody>
          <Heading as="h2" size="lg" mb={4}>
            Pending Report
          </Heading>
          <Tabs variant="isFitted variant='enclosed'">
            <TabList mb="1em">
              <Tab _selected={{ color: "white", bg: "#67214B" }}>
                Product Name Report
              </Tab>
              <Tab _selected={{ color: "white", bg: "#67214B" }}>
                Composition
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <TableContainer>
                  <CSVLink
                    data={convertNameToCSV()}
                    filename={"table_data.csv"}
                    className="downloadButton"
                  >
                    Export <SiMicrosoftexcel style={{ marginLeft: "15px" }} />
                  </CSVLink>
                  <Table size="lg" borderWidth="1px" border="1px solid">
                    <Thead backgroundColor={"#252B6194"} color={"black"}>
                      <Tr borderWidth="1px">
                        <Th>Sr No</Th>
                        <Th>Name</Th>
                        <Th>Composition</Th>
                        <Th>Quantity</Th>
                      </Tr>
                    </Thead>
                    <Tbody borderWidth="1px">
                      {data.length > 0 ? (
                        data.map((item, index) => (
                          <Tr
                            key={index}
                            _hover={{
                              background: "#f5f5f5",
                              cursor: "pointer",
                            }}
                          >
                            <Td>{index + 1}</Td>
                            <Td>{item?._id?.name}</Td>
                            <Td>{item?._id?.composition}</Td>
                            <Td>{item.totalQuantity}</Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td>No Live Orders</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer>
                  <CSVLink
                    data={convertCompToCSV()}
                    filename={"table_data.csv"}
                    className="downloadButton"
                  >
                    Export <SiMicrosoftexcel style={{ marginLeft: "15px" }} />
                  </CSVLink>
                  <Table size="lg" borderWidth="1px" border="1px solid">
                    <Thead backgroundColor={"#252B6194"} color={"black"}>
                      <Tr borderWidth="1px">
                        <Th>Sr No</Th>
                        <Th>Composition</Th>
                        <Th>Quantity</Th>
                      </Tr>
                    </Thead>
                    <Tbody borderWidth="1px">
                      {compData.length > 0 ? (
                        compData.map((item, index) => (
                          <Tr
                            key={index}
                            _hover={{
                              background: "#f5f5f5",
                              cursor: "pointer",
                            }}
                          >
                            <Td>{index + 1}</Td>
                            <Td>{item?._id}</Td>
                            <Td>{item.totalQuantity}</Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <Td>No Live Orders</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </div>
  );
};

export default PendingReport;
