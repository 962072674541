import {
  Button,
  Card,
  CardBody,
  HStack,
  Heading,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { addgst, editgstactive, getAllgst } from "../../apis/productApis";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Skeleton } from "@chakra-ui/react";

const GstType = () => {
  const [Gst, setGst] = useState([]);
  const [loading, setloading] = useState(false);

  const [isaddModal, setIsaddModal] = useState(false);
  const onCloseAddModal = () => setIsaddModal(false);

  const onaddModal = () => setIsaddModal(true);

  async function fetchData() {
    setloading(true);
    const Gst = await getAllgst();

    if (Gst) {
      setGst(Gst);
      setloading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const initial = {
    name: "",
  };

  const [formData, setFormData] = useState(initial);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { types: [formData] };
    setloading(true);
    const req = await addgst(data);
    if (req) {
      fetchData();
      onCloseAddModal();
      setFormData(initial);
    }
  };

  const activate = async (item, active) => {
    const data = {
      id: item?._id,
      active: active,
    };
    setloading(true);
    const req = await editgstactive(data);
    if (req) {
      fetchData();
    }
  };
  return (
    <div className="TabContainer">
      <Card>
        <CardBody>
          <HStack
               style={{
                display: "flex",
                justifyContent:"space-between",
                alignItems:"center",
                marginBottom:"1rem"
              }}
          >
            <Heading>Gst Types</Heading>
            <Button className="button" onClick={onaddModal}>
              + ADD
            </Button>
          </HStack>
          {loading ? (
            <Stack>
              <Skeleton height="40px" />
              <Skeleton height="40px" />
              <Skeleton height="40px" />
              <Skeleton height="40px" />
              <Skeleton height="40px" />
            </Stack>
          ) : (
            <VStack>
              {Gst?.length > 0 &&
                Gst.map((item) => (
                  <HStack
                    key={item?._id}
                    className="list-Item"
                    onClick={() => {
                      setFormData(item);
                    }}
                  >
                    <p>{item?.name} %</p>

                    <Menu>
                      <MenuButton
                        backgroundColor={item.active ? "#2E8B57aa" : "#FFCCCB"}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        style={{ width: 120 }}
                      >
                        {item.active ? "Active" : "Inactive"}
                      </MenuButton>
                      <MenuList>
                        {!item.active ? (
                          <MenuItem onClick={() => activate(item, true)}>
                            Activate
                          </MenuItem>
                        ) : (
                          <MenuItem onClick={() => activate(item, false)}>
                            Deactivate
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </HStack>
                ))}
            </VStack>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={isaddModal}
        onClose={onCloseAddModal}
        isCentered
        size={"lg"}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Add Gst Percentage</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <form className="categoryForm" onSubmit={handleSubmit}>
                <HStack>
                  <div className="categorydiv">
                    <label className="categoryLable">Gst Percentage(%)</label>
                    <Input
                      className="categoryInput"
                      type="number"
                      required
                      name="name"
                      placeholder="Gst Percentage"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </HStack>
                <button className="categoryButton" type="submit">
                  Add
                </button>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </div>
  );
};

export default GstType;
