import React, { useEffect, useState } from "react";
import SideDrawer from "./SideDrawer";
import { getOrdersAccordingToStatus } from "../../apis/orderApis";
import { FaEye } from "react-icons/fa";
import { formatDate } from "../../helpers/DateFormatter";
import {
  Card,
  CardBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import html2pdf from "html2pdf.js";
import { generateInvoice } from "../../helpers/genrateInvoice";

const Home = () => {
  const [data, setDate] = useState([]);

  const handleClick = (item) => {
    console.log("inside home", item, item?.userId);
    if (item && item.products && item.userId) {
      const htmlContent = generateInvoice(item, item.userId);
      const pdf = html2pdf()
        .from(htmlContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const blob = pdf.output("blob");
          const url = URL.createObjectURL(blob);
          window.open(url, "_blank"); // Open the PDF in a new tab
        });
      const pdfBlob = pdf.output("blob");

      // Check if pdfBlob is valid
      if (pdfBlob instanceof Blob) {
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Create a hidden anchor element
        const anchor = document.createElement("a");
        anchor.href = pdfUrl;
        anchor.download = "invoice.pdf"; // Specify the filename for download
        anchor.style.display = "none";

        // Append the anchor to the document body
        document.body.appendChild(anchor);

        // Trigger a click event on the anchor to start the download
        anchor.click();

        // Clean up by removing the anchor element
        document.body.removeChild(anchor);
      } else {
        console.error("Invalid pdfBlob:", pdfBlob);
      }
    } else {
      console.error("Invalid item object");
    }
  };

  useEffect(() => {
    async function fetchData() {
      const req = await getOrdersAccordingToStatus("Live");

      if (req) {
        setDate(req.reverse());
      }
    }
    fetchData();
  }, []);

  return (
    <div className="AdminHome">
     <SideDrawer />

      <Card w="100%">
        <CardBody>
          <p> Live Orders</p>
          <TableContainer>
            <Table size="lg" borderWidth="1px" border="1px solid">
              <Thead backgroundColor={"#252B6194"} color={"black"}>
                <Tr borderWidth="1px">
                  <Th width={30}>#</Th>
                  <Th>Date</Th>
                  <Th>Client Name</Th>
                  <Th>Mobile No</Th>
                  <Th textAlign="center">Order</Th>
                </Tr>
              </Thead>
              <Tbody borderWidth="1px">
                {data.length > 0 ? (
                  data.map((item, index) => (
                    <Tr
                      key={index}
                      _hover={{ background: "#f5f5f5" }}
                    >
                      <Td>{index + 1}</Td>
                      <Td>{formatDate(item?.orderDate)}</Td>
                      <Td>
                        {item?.userId?.userType === "Doctor"
                          ? "Dr. " + item?.userId?.name
                          : item?.userId?.name}
                      </Td>
                      <Td>{item?.userId?.mobile}</Td>
                      <Td
                        // border="1px"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        
                        <div style={{display:"flex" , gap:"1rem" , alignItems:"center" , cursor: "pointer"}} onClick={() => handleClick(item)}>
                        <FaEye />
                        <p >View</p>
                        </div>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td>No Live Orders</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>
      {/* <main>
        {data.length > 0 ? (
          data.map((item, index) => (
            <div key={index}>
              <div>
                <p> {formatDate(item?.orderDate)}</p>
              </div>
              <div>
                <p>
                  {item?.userId?.userType === "Doctor"
                    ? "Dr. " + item?.userId?.name
                    : ""}
                </p>
              </div>
              <div>
                <p>{item?.userId?.mobile}</p>
              </div>
              <div>
                <FaEye />
                <a>View</a>
              </div>
            </div>
          ))
        ) : (
          <p>No Live Orders</p>
        )}
      </main> */}
    </div>
  );
};

export default Home;
